import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  material: null,
  materialSuccess: null,
  materialError: null,
  materialLoading: false,

  materials: null,
  materialsSuccess: null,
  materialsError: null,
  materialsLoading: false,

  materialsFiltered: null,
  materialsFilteredCount: null,
  materialsFilteredSuccess: null,
  materialsFilteredError: null,
  materialsFilteredLoading: false,

  materialUpdateSuccess: null,
  materialUpdateError: null,
  materialUpdateLoading: false,

  materialDeleteSuccess: null,
  materialDeleteError: null,
  materialDeleteLoading: false,
};

//get all materials
const materialsFetchStart = (state) => {
  return updateObject(state, {
    materialsSuccess: null,
    materialsError: null,
    materialsLoading: true,
  });
};
const materialsFetchSuccess = (state, action) => {
  return updateObject(state, {
    materials: action.materials,
    materialsSuccess: true,
    materialsError: null,
    materialsLoading: false,
  });
};
const materialsFetchFail = (state, action) => {
  return updateObject(state, {
    materialsError: action.error,
    materialsSuccess: false,
    materialsLoading: false,
  });
};

//get filtered materials
const materialsFilteredFetchStart = (state) => {
  return updateObject(state, {
    materialsFilteredSuccess: null,
    materialsFilteredError: null,
    materialsFilteredLoading: true,
  });
};
const materialsFilteredFetchSuccess = (state, action) => {
  return updateObject(state, {
    materialsFiltered: action.materials,
    materialsFilteredSuccess: true,
    materialsFilteredError: null,
    materialsFilteredLoading: false,
  });
};
const materialsFilteredCountFetchSuccess = (state, action) => {
  return updateObject(state, {
    materialsFilteredCount: action.count,
  });
};
const materialsFilteredFetchFail = (state, action) => {
  return updateObject(state, {
    materialsFilteredError: action.error,
    materialsFilteredSuccess: false,
    materialsFilteredLoading: false,
  });
};

//get material by Id
const materialFetchStart = (state) => {
  return updateObject(state, {
    materialSuccess: null,
    materialError: null,
    materialLoading: true,
  });
};
const materialFetchSuccess = (state, action) => {
  return updateObject(state, {
    material: action.material,
    materialSuccess: true,
    materialError: null,
    materialLoading: false,
  });
};
const materialFetchFail = (state, action) => {
  return updateObject(state, {
    materialError: action.error,
    materialSuccess: false,
    materialLoading: false,
  });
};

//create material
const materialCreateStart = (state) => {
  return updateObject(state, {
    materialSuccess: null,
    materialError: null,
    materialLoading: true,
  });
};
const materialCreateSuccess = (state) => {
  return updateObject(state, {
    materialSuccess: true,
    materialError: null,
    materialLoading: false,
  });
};
const materialCreateFail = (state, action) => {
  return updateObject(state, {
    materialError: action.error,
    materialSuccess: false,
    materialLoading: false,
  });
};

//update material
const materialUpdateStart = (state) => {
  return updateObject(state, {
    materialUpdateSuccess: null,
    materialUpdateError: null,
    materialUpdateLoading: true,
  });
};
const materialUpdateSuccess = (state, action) => {
  return updateObject(state, {
    material: action.material,
    materialUpdateSuccess: true,
    materialUpdateError: null,
    materialUpdateLoading: false,
  });
};
const materialUpdateFail = (state, action) => {
  return updateObject(state, {
    materialUpdateError: action.error,
    materialUpdateSuccess: false,
    materialUpdateLoading: false,
  });
};

//delete material
const materialDeleteStart = (state) => {
  return updateObject(state, {
    materialDeleteSuccess: null,
    materialDeleteError: null,
    materialDeleteLoading: true,
  });
};
const materialDeleteSuccess = (state) => {
  return updateObject(state, {
    materialDeleteSuccess: true,
    materialDeleteError: null,
    materialDeleteLoading: false,
  });
};
const materialDeleteFail = (state, action) => {
  return updateObject(state, {
    materialDeleteError: action.error,
    materialDeleteSuccess: false,
    materialDeleteLoading: false,
  });
};

//clear material message
const materialMessageClearSuccess = (state) => {
  return updateObject(state, {
    materialSuccess: null,
    materialError: null,
    materialsSuccess: null,
    materialsError: null,
    materialsFilteredSuccess: null,
    materialsFilteredError: null,
    materialUpdateSuccess: null,
    materialUpdateError: null,
    materialDeleteSuccess: null,
    materialDeleteError: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MATERIAL_START:
      return materialFetchStart(state);
    case actionTypes.FETCH_MATERIAL_SUCCESS:
      return materialFetchSuccess(state, action);
    case actionTypes.FETCH_MATERIAL_FAIL:
      return materialFetchFail(state, action);

    case actionTypes.FETCH_MATERIALS_START:
      return materialsFetchStart(state);
    case actionTypes.FETCH_MATERIALS_SUCCESS:
      return materialsFetchSuccess(state, action);
    case actionTypes.FETCH_MATERIALS_FAIL:
      return materialsFetchFail(state, action);

    case actionTypes.FETCH_FILTERED_MATERIALS_START:
      return materialsFilteredFetchStart(state);
    case actionTypes.FETCH_FILTERED_MATERIALS_SUCCESS:
      return materialsFilteredFetchSuccess(state, action);
    case actionTypes.FETCH_FILTERED_MATERIALS_COUNT_SUCCESS:
      return materialsFilteredCountFetchSuccess(state, action);
    case actionTypes.FETCH_FILTERED_MATERIALS_FAIL:
      return materialsFilteredFetchFail(state, action);

    case actionTypes.CREATE_MATERIAL_START:
      return materialCreateStart(state);
    case actionTypes.CREATE_MATERIAL_SUCCESS:
      return materialCreateSuccess(state);
    case actionTypes.CREATE_MATERIAL_FAIL:
      return materialCreateFail(state, action);

    case actionTypes.UPDATE_MATERIAL_START:
      return materialUpdateStart(state);
    case actionTypes.UPDATE_MATERIAL_SUCCESS:
      return materialUpdateSuccess(state, action);
    case actionTypes.UPDATE_MATERIAL_FAIL:
      return materialUpdateFail(state, action);

    case actionTypes.DELETE_MATERIAL_START:
      return materialDeleteStart(state);
    case actionTypes.DELETE_MATERIAL_SUCCESS:
      return materialDeleteSuccess(state);
    case actionTypes.DELETE_MATERIAL_FAIL:
      return materialDeleteFail(state, action);

    case actionTypes.CLEAR_MATERIAL_MESSAGE_SUCCESS:
      return materialMessageClearSuccess(state);

    default:
      return state;
  }
};
export default reducer;
