import React from "react";
import Login from "../Authentication/Login";
import Register from "../Authentication/Register";
import ForgotPassword from "../Authentication/ForgotPassword";
import ResetPassword from "../Authentication/ResetPassword";
import { Modal, ModalBody } from "reactstrap";
import { FaTimes } from "react-icons/fa";

const AuthModal = ({
  authModal,
  mode,
  setMode,
  token,
  toggleAuthModal,
  setAuthModal,
}) => {
  return (
    <Modal fullscreen="lg" isOpen={authModal} centered>
      <ModalBody>
        {mode === "login" && (
          <Login setMode={setMode} token={token} setAuthModal={setAuthModal} />
        )}
        {mode === "register" && (
          <Register setMode={setMode} toggleAuthModal={toggleAuthModal} />
        )}
        {mode === "forgot" && <ForgotPassword setMode={setMode} />}
        {mode === "reset" && <ResetPassword token={token} setMode={setMode} />}
      </ModalBody>
      <div
        className="modal-close-button"
        onClick={() => {
          localStorage.removeItem("orderData");
          toggleAuthModal();
        }}
      >
        <FaTimes size={20} />
      </div>
    </Modal>
  );
};

export default AuthModal;
