import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import Loader from "../../layouts/Loader";
import { API } from "../../../store/config";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/exports";

const Projects = ({ projectsFetch, projects, projectsLoading }) => {
  const limit = 3;
  const skip = 0;

  useEffect(() => {
    projectsFetch(skip, limit, {});
  }, [projectsFetch]);

  const renderProjects = (projects) =>
    projects.map((item, i) => (
      <Col key={i} lg={4}>
        <div className="card-block">
          <img
            src={`${API}/uploads/projects/${item._id}/photo.jpeg`}
            alt={item.title}
            className="image-round"
          />
          <h5 className="my-3">
            <Link to={`/projects/${item.slug}`}> {item.title} </Link>
          </h5>
          <Link to={`/projects/${item.slug}`} className="btn-custom ">
            View
          </Link>
        </div>
      </Col>
    ));
  return (
    <Container>
      {!projectsLoading && projects ? (
        <Row>
          <Col xs={12}>
            <Col className="text-center mb-4">
              <h4 className="mb-2">latest projects</h4>
              <p>Successfully completed work projects in the last period</p>
            </Col>
          </Col>
          {renderProjects(projects)}
        </Row>
      ) : (
        <Loader />
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    projects: state.project.projects,
    projectsSuccess: state.project.projectsSuccess,
    projectsError: state.project.projectsError,
    projectsLoading: state.project.projectsLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    projectsFetch: (skip, limit, filters) =>
      dispatch(actions.projectsFetch(skip, limit, filters)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
