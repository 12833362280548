import React from "react";
import { Container, Row, Col } from "reactstrap";
import image from "../../../assets/img/about.png";

const data = {
  title: "our legacy",
  description1:
    "Founded by Mr. Toni Gonev, the company becomes a leader in the region when it comes to stone processing for residential and commercial use.",
  description2:
    "Huge commitment, energy and passion is what we constantly invest  and what makes our work extremely professional and outstanding.",
  description3: "Since 2005, we are building our reputation, our legacy.",
  image: image,
};

const About = () => {
  return (
    <Container className="extended">
      <Row>
        <Col>
          <div className="banner pattern-bg">
            <Row className="my-2">
              <Col xs={12} lg={4} className="banner-images">
                <img src={image} alt="banner" />
              </Col>
              <Col
                xs={12}
                lg={8}
                className="d-flex flex-column justify-content-center mt-4 mt-lg-0"
              >
                <h4>{data.title}</h4>
                <p>{data.description1}</p>
                <p>{data.description2}</p>
                <p>{data.description3}</p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
