import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  blogCategory: null,
  blogCategorySuccess: null,
  blogCategoryError: null,
  blogCategoryLoading: false,

  blogCategories: null,
  blogCategoriesCount: null,
  blogCategoriesSuccess: null,
  blogCategoriesError: null,
  blogCategoriesLoading: false,

  blogCategoriesFiltered: null,
  blogCategoriesFilteredCount: null,
  blogCategoriesFilteredSuccess: null,
  blogCategoriesFilteredError: null,
  blogCategoriesFilteredLoading: false,

  blogCategoryUpdateSuccess: null,
  blogCategoryUpdatError: null,
  blogCategoryUpdateLoading: false,

  blogCategoryDeleteSuccess: null,
  blogCategoryDeleteError: null,
  blogCategoryDeleteLoading: false,
};

//get all categories
const blogCategoriesFetchStart = (state) => {
  return updateObject(state, {
    blogCategoriesSuccess: null,
    blogCategoriesError: null,
    blogCategoriesLoading: true,
  });
};
const blogCategoriesFetchSuccess = (state, action) => {
  return updateObject(state, {
    blogCategories: action.categories,
    blogCategoriesSuccess: true,
    blogCategoriesError: null,
    blogCategoriesLoading: false,
  });
};
const blogCategoriesCountFetchSuccess = (state, action) => {
  return updateObject(state, {
    blogCategoriesCount: action.count,
  });
};
const blogCategoriesFetchFail = (state, action) => {
  return updateObject(state, {
    blogCategoriesError: action.error,
    blogCategoriesSuccess: false,
    blogCategoriesLoading: false,
  });
};

//get filtered category
const blogCategoriesFilteredFetchStart = (state) => {
  return updateObject(state, {
    blogCategoriesFilteredSuccess: null,
    blogCategoriesFilteredError: null,
    blogCategoriesFilteredLoading: true,
  });
};
const blogCategoriesFilteredFetchSuccess = (state, action) => {
  return updateObject(state, {
    blogCategoriesFiltered: action.blogCategories,
    blogCategoriesFilteredSuccess: true,
    blogCategoriesFilteredError: null,
    blogCategoriesFilteredLoading: false,
  });
};
const blogCategoriesFilteredCountFetchSuccess = (state, action) => {
  return updateObject(state, {
    blogCategoriesFilteredCount: action.count,
  });
};
const blogCategoriesFilteredFetchFail = (state, action) => {
  return updateObject(state, {
    blogCategoriesFilteredError: action.error,
    blogCategoriesFilteredSuccess: false,
    blogCategoriesFilteredLoading: false,
  });
};

//get category by Name
const blogCategoryFetchStart = (state) => {
  return updateObject(state, {
    blogCategorySuccess: null,
    blogCategoryError: null,
    blogCategoryLoading: true,
  });
};
const blogCategoryFetchSuccess = (state, action) => {
  return updateObject(state, {
    blogCategory: action.category,
    blogCategorySuccess: true,
    blogCategoryError: null,
    blogCategoryLoading: false,
  });
};
const blogCategoryFetchFail = (state, action) => {
  return updateObject(state, {
    blogCategoryError: action.error,
    blogCategorySuccess: false,
    blogCategoryLoading: false,
  });
};

//create category
const blogCategoryCreateStart = (state) => {
  return updateObject(state, {
    blogCategorySuccess: null,
    blogCategoryError: null,
    blogCategoryLoading: true,
  });
};
const blogCategoryCreateSuccess = (state) => {
  return updateObject(state, {
    blogCategorySuccess: true,
    blogCategoryError: null,
    blogCategoryLoading: false,
  });
};
const blogCategoryCreateFail = (state, action) => {
  return updateObject(state, {
    blogCategoryError: action.error,
    blogCategorySuccess: false,
    blogCategoryLoading: false,
  });
};

//update category
const blogCategoryUpdateStart = (state) => {
  return updateObject(state, {
    blogCategoryUpdateSuccess: null,
    blogCategoryUpdateError: null,
    blogCategoryUpdateLoading: true,
  });
};
const blogCategoryUpdateSuccess = (state, action) => {
  return updateObject(state, {
    blogCategory: action.category,
    blogCategoryUpdateSuccess: true,
    blogCategoryUpdateError: null,
    blogCategoryUpdateLoading: false,
  });
};
const blogCategoryUpdateFail = (state, action) => {
  return updateObject(state, {
    blogCategoryUpdateError: action.error,
    blogCategoryUpdateSuccess: false,
    blogCategoryUpdateLoading: false,
  });
};

//delete category
const blogCategoryDeleteStart = (state) => {
  return updateObject(state, {
    blogCategoryDeleteSuccess: null,
    blogCategoryDeleteError: null,
    blogCategoryDeleteLoading: true,
  });
};
const blogCategoryDeleteSuccess = (state) => {
  return updateObject(state, {
    blogCategoryDeleteSuccess: true,
    blogCategoryDeleteError: null,
    blogCategoryDeleteLoading: false,
  });
};
const blogCategoryDeleteFail = (state, action) => {
  return updateObject(state, {
    blogCategoryDeleteError: action.error,
    blogCategoryDeleteSuccess: false,
    blogCategoryDeleteLoading: false,
  });
};

//clear category message
const blogCategoryMessageClearSuccess = (state) => {
  return updateObject(state, {
    blogCategorySuccess: null,
    blogCategoryError: null,
    blogCategoriesSuccess: null,
    blogCategoriesError: null,
    blogCategoryUpdateSuccess: null,
    blogCategoryUpdatError: null,
    blogCategoryDeleteSuccess: null,
    blogCategoryDeleteError: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_BLOG_CATEGORIES_START:
      return blogCategoriesFetchStart(state);
    case actionTypes.FETCH_BLOG_CATEGORIES_SUCCESS:
      return blogCategoriesFetchSuccess(state, action);
    case actionTypes.FETCH_BLOG_CATEGORIES_COUNT_SUCCESS:
      return blogCategoriesCountFetchSuccess(state, action);
    case actionTypes.FETCH_BLOG_CATEGORIES_FAIL:
      return blogCategoriesFetchFail(state, action);

    case actionTypes.FETCH_BLOG_CATEGORIES_FILTERED_START:
      return blogCategoriesFilteredFetchStart(state);
    case actionTypes.FETCH_BLOG_CATEGORIES_FILTERED_SUCCESS:
      return blogCategoriesFilteredFetchSuccess(state, action);
    case actionTypes.FETCH_BLOG_CATEGORIES_FILTERED_COUNT_SUCCESS:
      return blogCategoriesFilteredCountFetchSuccess(state, action);
    case actionTypes.FETCH_BLOG_CATEGORIES_FILTERED_FAIL:
      return blogCategoriesFilteredFetchFail(state, action);

    case actionTypes.FETCH_BLOG_CATEGORY_START:
      return blogCategoryFetchStart(state);
    case actionTypes.FETCH_BLOG_CATEGORY_SUCCESS:
      return blogCategoryFetchSuccess(state, action);
    case actionTypes.FETCH_BLOG_CATEGORY_FAIL:
      return blogCategoryFetchFail(state, action);

    case actionTypes.CREATE_BLOG_CATEGORY_START:
      return blogCategoryCreateStart(state);
    case actionTypes.CREATE_BLOG_CATEGORY_SUCCESS:
      return blogCategoryCreateSuccess(state);
    case actionTypes.CREATE_BLOG_CATEGORY_FAIL:
      return blogCategoryCreateFail(state, action);

    case actionTypes.UPDATE_BLOG_CATEGORY_START:
      return blogCategoryUpdateStart(state);
    case actionTypes.UPDATE_BLOG_CATEGORY_SUCCESS:
      return blogCategoryUpdateSuccess(state, action);
    case actionTypes.UPDATE_BLOG_CATEGORY_FAIL:
      return blogCategoryUpdateFail(state, action);

    case actionTypes.DELETE_BLOG_CATEGORY_START:
      return blogCategoryDeleteStart(state);
    case actionTypes.DELETE_BLOG_CATEGORY_SUCCESS:
      return blogCategoryDeleteSuccess(state);
    case actionTypes.DELETE_BLOG_CATEGORY_FAIL:
      return blogCategoryDeleteFail(state, action);

    case actionTypes.CLEAR_BLOG_CATEGORY_MESSAGE_SUCCESS:
      return blogCategoryMessageClearSuccess(state);

    default:
      return state;
  }
};
export default reducer;
