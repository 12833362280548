import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { store } from "./store/storeConfig/store";
import * as serviceWorker from "./serviceWorker";

//css
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.scss";

//redux
import { Provider } from "react-redux";
import Loader from "./components/layouts/Loader";

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
