import React from "react";
import { Link } from "react-router-dom";

const Navigation = () => {
  const navigationmenu = [
    {
      id: 1,
      linkText: "home",
      link: "/",
    },
    {
      id: 2,
      linkText: "projects",
      link: "/projects",
    },
    {
      id: 3,
      linkText: "materials",
      link: "/materials",
    },
    {
      id: 4,
      linkText: "blog",
      link: "/blog",
    },
  ];
  return (
    <ul className="navbar-nav d-none d-xl-flex">
      {navigationmenu.map((item, i) => (
        <li key={i} className="menu-item" onClick={item.onClick}>
          <Link to={item.link}> {item.linkText} </Link>
        </li>
      ))}
    </ul>
  );
};

export default Navigation;
