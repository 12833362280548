import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  mainBanner: null,
  mainBannerSuccess: null,
  mainBannerError: null,
  mainBannerLoading: false,

  mainBannersFiltered: null,
  mainBannersFilteredCount: null,
  mainBannersFilteredSuccess: null,
  mainBannersFilteredError: null,
  mainBannersFilteredLoading: false,

  mainBannerUpdateSuccess: null,
  mainBannerUpdateError: null,
  mainBannerUpdateLoading: false,

  mainBannerDeleteSuccess: null,
  mainBannerDeleteError: null,
  mainBannerDeleteLoading: false,
};

//get main banner by ID
const mainBannerFetchStart = (state) => {
  return updateObject(state, {
    mainBannerSuccess: null,
    mainBannerError: null,
    mainBannerLoading: true,
  });
};
const mainBannerFetchSuccess = (state, action) => {
  return updateObject(state, {
    mainBanner: action.mainBanner,
    mainBannerSuccess: true,
    mainBannerError: null,
    mainBannerLoading: false,
  });
};
const mainBannerFetchFail = (state, action) => {
  return updateObject(state, {
    mainBannerError: action.error,
    mainBannerSuccess: false,
    mainBannerLoading: false,
  });
};

//get filtered main banners
const filteredMainBannersFetchStart = (state) => {
  return updateObject(state, {
    mainBannersFilteredSuccess: null,
    mainBannersFilteredError: null,
    mainBannersFilteredLoading: true,
  });
};
const filteredMainBannersFetchSuccess = (state, action) => {
  return updateObject(state, {
    mainBannersFiltered: action.mainBanners,
    mainBannersFilteredSuccess: true,
    mainBannersFilteredError: null,
    mainBannersFilteredLoading: false,
  });
};
const filteredMainBannersCountFetchSuccess = (state, action) => {
  return updateObject(state, {
    mainBannersFilteredCount: action.count,
  });
};
const filteredMainBannersFetchFail = (state, action) => {
  return updateObject(state, {
    mainBannerFilteredError: action.error,
    mainBannerFilteredSuccess: false,
    mainBannerFilteredLoading: false,
  });
};

//create main banner
const mainBannerCreateStart = (state) => {
  return updateObject(state, {
    mainBannerSuccess: null,
    mainBannerError: null,
    mainBannerLoading: true,
  });
};
const mainBannerCreateSuccess = (state) => {
  return updateObject(state, {
    mainBannerSuccess: true,
    mainBannerError: null,
    mainBannerLoading: false,
  });
};
const mainBannerCreateFail = (state, action) => {
  return updateObject(state, {
    mainBannerError: action.error,
    mainBannerSuccess: false,
    mainBannerLoading: false,
  });
};

//update main banner
const mainBannerUpdateStart = (state) => {
  return updateObject(state, {
    mainBannerUpdateSuccess: null,
    mainBannerUpdateError: null,
    mainBannerUpdateLoading: true,
  });
};
const mainBannerUpdateSuccess = (state, action) => {
  return updateObject(state, {
    mainBanner: action.mainBanner,
    mainBannerUpdateSuccess: true,
    mainBannerUpdateError: null,
    mainBannerUpdateLoading: false,
  });
};
const mainBannerUpdateFail = (state, action) => {
  return updateObject(state, {
    mainBannerUpdateError: action.error,
    mainBannerUpdateSuccess: false,
    mainBannerUpdateLoading: false,
  });
};

//delete main banner
const mainBannerDeleteStart = (state) => {
  return updateObject(state, {
    mainBannerDeleteSuccess: null,
    mainBannerDeleteError: null,
    mainBannerDeleteLoading: true,
  });
};
const mainBannerDeleteSuccess = (state) => {
  return updateObject(state, {
    mainBannerDeleteSuccess: true,
    mainBannerDeleteError: null,
    mainBannerDeleteLoading: false,
  });
};
const mainBannerDeleteFail = (state, action) => {
  return updateObject(state, {
    mainBannerDeleteError: action.error,
    mainBannerDeleteSuccess: false,
    mainBannerDeleteLoading: false,
  });
};

const clearMainBannerMessageSuccess = (state) => {
  return updateObject(state, {
    mainBannerSuccess: null,
    mainBannerError: null,
    mainBannerFilteredSuccess: null,
    mainBannerFilteredError: null,
    mainBannerUpdateStart: null,
    mainBannerUpdateError: null,
    mainBannerDeleteStart: null,
    mainBannerDeleteError: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MAIN_BANNER_START:
      return mainBannerFetchStart(state);
    case actionTypes.FETCH_MAIN_BANNER_SUCCESS:
      return mainBannerFetchSuccess(state, action);
    case actionTypes.FETCH_MAIN_BANNER_FAIL:
      return mainBannerFetchFail(state, action);
    case actionTypes.FETCH_FILTERED_MAIN_BANNERS_START:
      return filteredMainBannersFetchStart(state);
    case actionTypes.FETCH_FILTERED_MAIN_BANNERS_SUCCESS:
      return filteredMainBannersFetchSuccess(state, action);
    case actionTypes.FETCH_FILTERED_MAIN_BANNERS_COUNT_SUCCESS:
      return filteredMainBannersCountFetchSuccess(state, action);
    case actionTypes.FETCH_FILTERED_MAIN_BANNERS_FAIL:
      return filteredMainBannersFetchFail(state, action);
    case actionTypes.CREATE_MAIN_BANNER_START:
      return mainBannerCreateStart(state);
    case actionTypes.CREATE_MAIN_BANNER_SUCCESS:
      return mainBannerCreateSuccess(state);
    case actionTypes.CREATE_MAIN_BANNER_FAIL:
      return mainBannerCreateFail(state, action);
    case actionTypes.UPDATE_MAIN_BANNER_START:
      return mainBannerUpdateStart(state);
    case actionTypes.UPDATE_MAIN_BANNER_SUCCESS:
      return mainBannerUpdateSuccess(state, action);
    case actionTypes.UPDATE_MAIN_BANNER_FAIL:
      return mainBannerUpdateFail(state, action);
    case actionTypes.DELETE_MAIN_BANNER_START:
      return mainBannerDeleteStart(state);
    case actionTypes.DELETE_MAIN_BANNER_SUCCESS:
      return mainBannerDeleteSuccess(state);
    case actionTypes.DELETE_MAIN_BANNER_FAIL:
      return mainBannerDeleteFail(state, action);
    case actionTypes.CLEAR_MAIN_BANNER_MESSAGE_SUCCESS:
      return clearMainBannerMessageSuccess(state);
    default:
      return state;
  }
};
export default reducer;
