//user
export const FETCH_USER_START = "FETCH_USER_START";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAIL = "FETCH_USER_FAIL";

export const SIGNIN_USER_START = "SIGNIN_USER_START";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNIN_USER_FAIL = "SIGNIN_USER_FAIL";

export const SIGNUP_USER_START = "SIGNUP_USER_START";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_FAIL = "SIGNUP_USER_FAIL";

export const SIGNOUT_USER_START = "SIGNOUT_USER_START";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const SIGNOUT_USER_FAIL = "SIGNOUT_USER_FAIL";

export const ACTIVATE_USER_START = "ACTIVATE_USER_START";
export const ACTIVATE_USER_SUCCESS = "ACTIVATE_USER_SUCCESS";
export const ACTIVATE_USER_FAIL = "ACTIVATE_USER_FAIL";

export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const UPDATE_USER_PASSWORD_START = "UPDATE_USER_PASSWORD_START";
export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS";
export const UPDATE_USER_PASSWORD_FAIL = "UPDATE_USER_PASSWORD_FAIL";

export const CLEAR_USER_MESSAGE_SUCCESS = "CLEAR_USER_MESSAGE_SUCCESS";

export const CLEAR_AUTHENTICATION_MESSAGE_SUCCESS =
  "CLEAR_AUTHENTICATION_MESSAGE_SUCCESS";

//contact
export const SEND_MAIL_START = "SEND_MAIL_START";
export const SEND_MAIL_SUCCESS = "SEND_MAIL_SUCCESS";
export const SEND_MAIL_FAIL = "SEND_MAIL_FAIL";

export const SEND_USER_ACTIVATION_MAIL_START =
  "SEND_USER_ACTIVATION_MAIL_START";
export const SEND_USER_ACTIVATION_MAIL_SUCCESS =
  "SEND_USER_ACTIVATION_MAIL_SUCCESS";
export const SEND_USER_ACTIVATION_MAIL_FAIL = "SEND_USER_ACTIVATION_MAIL_FAIL";

export const SEND_FORGOT_PASSWORD_MAIL_START =
  "SEND_FORGOT_PASSWORD_MAIL_START";
export const SEND_FORGOT_PASSWORD_MAIL_SUCCESS =
  "SEND_FORGOT_PASSWORD_MAIL_SUCCESS";
export const SEND_FORGOT_PASSWORD_MAIL_FAIL = "SEND_FORGOT_PASSWORD_MAIL_FAIL";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const VALIDATE_RESET_TOKEN_START = "VALIDATE_RESET_TOKEN_START";
export const VALIDATE_RESET_TOKEN_SUCCESS = "VALIDATE_RESET_TOKEN_SUCCESS";
export const VALIDATE_RESET_TOKEN_FAIL = "VALIDATE_RESET_TOKEN_FAIL";

export const CLEAR_CONTACT_MESSAGE_SUCCESS = "CLEAR_CONTACT_MESSAGE_SUCCESS";

//mainBanners
export const FETCH_MAIN_BANNER_START = "FETCH_MAIN_BANNER_START";
export const FETCH_MAIN_BANNER_SUCCESS = "FETCH_MAIN_BANNER_SUCCESS";
export const FETCH_MAIN_BANNER_FAIL = "FETCH_MAIN_BANNER_FAIL";

export const FETCH_FILTERED_MAIN_BANNERS_START =
  "FETCH_FILTERED_MAIN_BANNERS_START";
export const FETCH_FILTERED_MAIN_BANNERS_SUCCESS =
  "FETCH_FILTERED_MAIN_BANNERS_SUCCESS";
export const FETCH_FILTERED_MAIN_BANNERS_COUNT_SUCCESS =
  "FETCH_FILTERED_MAIN_BANNERS_COUNT_SUCCESS";
export const FETCH_FILTERED_MAIN_BANNERS_FAIL =
  "FETCH_FILTERED_MAIN_BANNERS_FAIL";

export const CREATE_MAIN_BANNER_START = "CREATE_MAIN_BANNER_START";
export const CREATE_MAIN_BANNER_SUCCESS = "CREATE_MAIN_BANNER_SUCCESS";
export const CREATE_MAIN_BANNER_FAIL = "CREATE_MAIN_BANNER_FAIL";

export const UPDATE_MAIN_BANNER_START = "UPDATE_MAIN_BANNER_START";
export const UPDATE_MAIN_BANNER_SUCCESS = "UPDATE_MAIN_BANNER_SUCCESS";
export const UPDATE_MAIN_BANNER_FAIL = "UPDATE_MAIN_BANNER_FAIL";

export const DELETE_MAIN_BANNER_START = "DELETE_MAIN_BANNER_START";
export const DELETE_MAIN_BANNER_SUCCESS = "DELETE_MAIN_BANNER_SUCCESS";
export const DELETE_MAIN_BANNER_FAIL = "DELETE_MAIN_BANNER_FAIL";

export const CLEAR_MAIN_BANNER_MESSAGE_SUCCESS =
  "CLEAR_MAIN_BANNER_MESSAGE_SUCCESS";

//blog category
export const FETCH_BLOG_CATEGORY_START = "FETCH_BLOG_CATEGORY_START";
export const FETCH_BLOG_CATEGORY_SUCCESS = "FETCH_BLOG_CATEGORY_SUCCESS";
export const FETCH_BLOG_CATEGORIES_COUNT_SUCCESS =
  "FETCH_BLOG_CATEGORIES_COUNT_SUCCESS";
export const FETCH_BLOG_CATEGORY_FAIL = "FETCH_BLOG_CATEGORY_FAIL";

export const FETCH_BLOG_CATEGORIES_START = "FETCH_BLOG_CATEGORIES_START";
export const FETCH_BLOG_CATEGORIES_SUCCESS = "FETCH_BLOG_CATEGORIES_SUCCESS";
export const FETCH_BLOG_CATEGORIES_FAIL = "FETCH_BLOG_CATEGORIES_FAIL";

export const FETCH_BLOG_CATEGORIES_FILTERED_START =
  "FETCH_BLOG_CATEGORIES_FILTERED_START";
export const FETCH_BLOG_CATEGORIES_FILTERED_SUCCESS =
  "FETCH_BLOG_CATEGORIES_FILTERED_SUCCESS";
export const FETCH_BLOG_CATEGORIES_FILTERED_COUNT_SUCCESS =
  "FETCH_BLOG_CATEGORIES_FILTERED_COUNT_SUCCESS";
export const FETCH_BLOG_CATEGORIES_FILTERED_FAIL =
  "FETCH_BLOG_CATEGORIES_FILTERED_FAIL";

export const CREATE_BLOG_CATEGORY_START = "CREATE_BLOG_CATEGORY_START";
export const CREATE_BLOG_CATEGORY_SUCCESS = "CREATE_BLOG_CATEGORY_SUCCESS";
export const CREATE_BLOG_CATEGORY_FAIL = "CREATE_BLOG_CATEGORY_FAIL";

export const UPDATE_BLOG_CATEGORY_START = "UPDATE_BLOG_CATEGORY_START";
export const UPDATE_BLOG_CATEGORY_SUCCESS = "UPDATE_BLOG_CATEGORY_SUCCESS";
export const UPDATE_BLOG_CATEGORY_FAIL = "UPDATE_BLOG_CATEGORY_FAIL";

export const DELETE_BLOG_CATEGORY_START = "DELETE_BLOG_CATEGORY_START";
export const DELETE_BLOG_CATEGORY_SUCCESS = "DELETE_BLOG_CATEGORY_SUCCESS";
export const DELETE_BLOG_CATEGORY_FAIL = "DELETE_BLOG_CATEGORY_FAIL";

export const CLEAR_BLOG_CATEGORY_MESSAGE_SUCCESS =
  "CLEAR_BLOG_CATEGORY_MESSAGE_SUCCESS";

//blog posts
export const FETCH_BLOG_POST_START = "FETCH_BLOG_POST_START";
export const FETCH_BLOG_POST_SUCCESS = "FETCH_BLOG_POST_SUCCESS";
export const FETCH_BLOG_POST_FAIL = "FETCH_BLOG_POST_FAIL";

export const FETCH_BLOG_POSTS_START = "FETCH_BLOG_POSTS_START";
export const FETCH_BLOG_POSTS_SUCCESS = "FETCH_BLOG_POSTS_SUCCESS";
export const FETCH_BLOG_POSTS_FAIL = "FETCH_BLOG_POSTS_FAIL";

export const FETCH_RELATED_BLOG_POSTS_START = "FETCH_RELATED_BLOG_POSTS_START";
export const FETCH_RELATED_BLOG_POSTS_SUCCESS =
  "FETCH_RELATED_BLOG_POSTS_SUCCESS";
export const FETCH_RELATED_BLOG_POSTS_FAIL = "FETCH_RELATED_BLOG_POSTS_FAIL";

export const FETCH_FILTERED_BLOG_POSTS_START =
  "FETCH_FILTERED_BLOG_POSTS_START";
export const FETCH_FILTERED_BLOG_POSTS_SUCCESS =
  "FETCH_FILTERED_BLOG_POSTS_SUCCESS";
export const FETCH_FILTERED_BLOG_POSTS_COUNT_SUCCESS =
  "FETCH_FILTERED_BLOG_POSTS_COUNT_SUCCESS";
export const FETCH_FILTERED_BLOG_POSTS_FAIL = "FETCH_FILTERED_BLOG_POSTS_FAIL";

export const CREATE_BLOG_POST_START = "CREATE_BLOG_POST_START";
export const CREATE_BLOG_POST_SUCCESS = "CREATE_BLOG_POST_SUCCESS";
export const CREATE_BLOG_POST_FAIL = "CREATE_BLOG_POST_FAIL";

export const UPDATE_BLOG_POST_START = "UPDATE_BLOG_POST_START";
export const UPDATE_BLOG_POST_SUCCESS = "UPDATE_BLOG_POST_SUCCESS";
export const UPDATE_BLOG_POST_FAIL = "UPDATE_BLOG_POST_FAIL";

export const DELETE_BLOG_POST_START = "DELETE_BLOG_POST_START";
export const DELETE_BLOG_POST_SUCCESS = "DELETE_BLOG_POST_SUCCESS";
export const DELETE_BLOG_POST_FAIL = "DELETE_BLOG_POST_FAIL";

export const UPDATE_BLOG_POST_IMPRESSION_START =
  "UPDATE_BLOG_POST_IMPRESSION_START";
export const UPDATE_BLOG_POST_IMPRESSION_SUCCESS =
  "UPDATE_BLOG_POST_IMPRESSION_SUCCESS";
export const UPDATE_BLOG_POST_IMPRESSION_FAIL =
  "UPDATE_BLOG_POST_IMPRESSION_FAIL";

export const CREATE_BLOG_POST_COMMENT_START = "CREATE_BLOG_POST_COMMENT_START";
export const CREATE_BLOG_POST_COMMENT_SUCCESS =
  "CREATE_BLOG_POST_COMMENT_SUCCESS";
export const CREATE_BLOG_POST_COMMENT_FAIL = "CREATE_BLOG_POST_COMMENT_FAIL";

export const CLEAR_BLOG_POST_MESSAGE_SUCCESS =
  "CLEAR_BLOG_POST_MESSAGE_SUCCESS";

//image
export const FETCH_FILTERED_IMAGES_START = "FETCH_FILTERED_IMAGES_START";
export const FETCH_FILTERED_IMAGES_SUCCESS = "FETCH_FILTERED_IMAGES_SUCCESS";
export const FETCH_FILTERED_IMAGES_COUNT_SUCCESS =
  "FETCH_FILTERED_IMAGES_COUNT_SUCCESS";
export const FETCH_FILTERED_IMAGES_FAIL = "FETCH_FILTERED_IMAGES_FAIL";

export const UPLOAD_IMAGE_START = "UPLOAD_IMAGE_START";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAIL = "UPLOAD_IMAGE_FAIL";

export const DELETE_IMAGE_START = "DELETE_IMAGE_START";
export const DELETE_IMAGE_SUCCESS = "DELETE_IMAGE_SUCCESS";
export const DELETE_IMAGE_FAIL = "DELETE_IMAGE_FAIL";

export const CLEAR_UPLOAD_IMAGE_MESSAGE_SUCCESS =
  "CLEAR_UPLOAD_IMAGE_MESSAGE_SUCCESS";

//material category
export const FETCH_MATERIAL_CATEGORY_START = "FETCH_MATERIAL_CATEGORY_START";
export const FETCH_MATERIAL_CATEGORY_SUCCESS =
  "FETCH_MATERIAL_CATEGORY_SUCCESS";
export const FETCH_MATERIAL_CATEGORIES_COUNT_SUCCESS =
  "FETCH_MATERIAL_CATEGORIES_COUNT_SUCCESS";
export const FETCH_MATERIAL_CATEGORY_FAIL = "FETCH_MATERIAL_CATEGORY_FAIL";

export const FETCH_MATERIAL_CATEGORIES_START =
  "FETCH_MATERIAL_CATEGORIES_START";
export const FETCH_MATERIAL_CATEGORIES_SUCCESS =
  "FETCH_MATERIAL_CATEGORIES_SUCCESS";
export const FETCH_MATERIAL_CATEGORIES_FAIL = "FETCH_MATERIAL_CATEGORIES_FAIL";

export const FETCH_MATERIAL_CATEGORIES_FILTERED_START =
  "FETCH_MATERIAL_CATEGORIES_FILTERED_START";
export const FETCH_MATERIAL_CATEGORIES_FILTERED_SUCCESS =
  "FETCH_MATERIAL_CATEGORIES_FILTERED_SUCCESS";
export const FETCH_MATERIAL_CATEGORIES_FILTERED_COUNT_SUCCESS =
  "FETCH_MATERIAL_CATEGORIES_FILTERED_COUNT_SUCCESS";
export const FETCH_MATERIAL_CATEGORIES_FILTERED_FAIL =
  "FETCH_MATERIAL_CATEGORIES_FILTERED_FAIL";

export const CREATE_MATERIAL_CATEGORY_START = "CREATE_MATERIAL_CATEGORY_START";
export const CREATE_MATERIAL_CATEGORY_SUCCESS =
  "CREATE_MATERIAL_CATEGORY_SUCCESS";
export const CREATE_MATERIAL_CATEGORY_FAIL = "CREATE_MATERIAL_CATEGORY_FAIL";

export const UPDATE_MATERIAL_CATEGORY_START = "UPDATE_MATERIAL_CATEGORY_START";
export const UPDATE_MATERIAL_CATEGORY_SUCCESS =
  "UPDATE_MATERIAL_CATEGORY_SUCCESS";
export const UPDATE_MATERIAL_CATEGORY_FAIL = "UPDATE_MATERIAL_CATEGORY_FAIL";

export const DELETE_MATERIAL_CATEGORY_START = "DELETE_MATERIAL_CATEGORY_START";
export const DELETE_MATERIAL_CATEGORY_SUCCESS =
  "DELETE_MATERIAL_CATEGORY_SUCCESS";
export const DELETE_MATERIAL_CATEGORY_FAIL = "DELETE_MATERIAL_CATEGORY_FAIL";

export const CLEAR_MATERIAL_CATEGORY_MESSAGE_SUCCESS =
  "CLEAR_MATERIAL_CATEGORY_MESSAGE_SUCCESS";

//material
export const FETCH_MATERIAL_START = "FETCH_MATERIAL_START";
export const FETCH_MATERIAL_SUCCESS = "FETCH_MATERIAL_SUCCESS";
export const FETCH_MATERIAL_FAIL = "FETCH_MATERIAL_FAIL";

export const FETCH_MATERIALS_START = "FETCH_MATERIALS_START";
export const FETCH_MATERIALS_SUCCESS = "FETCH_MATERIALS_SUCCESS";
export const FETCH_MATERIALS_FAIL = "FETCH_MATERIALS_FAIL";

export const FETCH_FILTERED_MATERIALS_START = "FETCH_FILTERED_MATERIALS_START";
export const FETCH_FILTERED_MATERIALS_SUCCESS =
  "FETCH_FILTERED_MATERIALS_SUCCESS";
export const FETCH_FILTERED_MATERIALS_COUNT_SUCCESS =
  "FETCH_FILTERED_MATERIALS_COUNT_SUCCESS";
export const FETCH_FILTERED_MATERIALS_FAIL = "FETCH_FILTERED_MATERIALS_FAIL";

export const CREATE_MATERIAL_START = "CREATE_MATERIAL_START";
export const CREATE_MATERIAL_SUCCESS = "CREATE_MATERIAL_SUCCESS";
export const CREATE_MATERIAL_FAIL = "CREATE_MATERIAL_FAIL";

export const UPDATE_MATERIAL_START = "UPDATE_MATERIAL_START";
export const UPDATE_MATERIAL_SUCCESS = "UPDATE_MATERIAL_SUCCESS";
export const UPDATE_MATERIAL_FAIL = "UPDATE_MATERIAL_FAIL";

export const DELETE_MATERIAL_START = "DELETE_MATERIAL_START";
export const DELETE_MATERIAL_SUCCESS = "DELETE_MATERIAL_SUCCESS";
export const DELETE_MATERIAL_FAIL = "DELETE_MATERIAL_FAIL";

export const CLEAR_MATERIAL_MESSAGE_SUCCESS = "CLEAR_MATERIAL_MESSAGE_SUCCESS";

//project category
export const FETCH_PROJECT_CATEGORY_START = "FETCH_PROJECT_CATEGORY_START";
export const FETCH_PROJECT_CATEGORY_SUCCESS = "FETCH_PROJECT_CATEGORY_SUCCESS";
export const FETCH_PROJECT_CATEGORIES_COUNT_SUCCESS =
  "FETCH_PROJECT_CATEGORIES_COUNT_SUCCESS";
export const FETCH_PROJECT_CATEGORY_FAIL = "FETCH_PROJECT_CATEGORY_FAIL";

export const FETCH_PROJECT_CATEGORIES_START = "FETCH_PROJECT_CATEGORIES_START";
export const FETCH_PROJECT_CATEGORIES_SUCCESS =
  "FETCH_PROJECT_CATEGORIES_SUCCESS";
export const FETCH_PROJECT_CATEGORIES_FAIL = "FETCH_PROJECT_CATEGORIES_FAIL";

export const FETCH_PROJECT_CATEGORIES_FILTERED_START =
  "FETCH_PROJECT_CATEGORIES_FILTERED_START";
export const FETCH_PROJECT_CATEGORIES_FILTERED_SUCCESS =
  "FETCH_PROJECT_CATEGORIES_FILTERED_SUCCESS";
export const FETCH_PROJECT_CATEGORIES_FILTERED_COUNT_SUCCESS =
  "FETCH_PROJECT_CATEGORIES_FILTERED_COUNT_SUCCESS";
export const FETCH_PROJECT_CATEGORIES_FILTERED_FAIL =
  "FETCH_PROJECT_CATEGORIES_FILTERED_FAIL";

export const CREATE_PROJECT_CATEGORY_START = "CREATE_PROJECT_CATEGORY_START";
export const CREATE_PROJECT_CATEGORY_SUCCESS =
  "CREATE_PROJECT_CATEGORY_SUCCESS";
export const CREATE_PROJECT_CATEGORY_FAIL = "CREATE_PROJECT_CATEGORY_FAIL";

export const UPDATE_PROJECT_CATEGORY_START = "UPDATE_PROJECT_CATEGORY_START";
export const UPDATE_PROJECT_CATEGORY_SUCCESS =
  "UPDATE_PROJECT_CATEGORY_SUCCESS";
export const UPDATE_PROJECT_CATEGORY_FAIL = "UPDATE_PROJECT_CATEGORY_FAIL";

export const DELETE_PROJECT_CATEGORY_START = "DELETE_PROJECT_CATEGORY_START";
export const DELETE_PROJECT_CATEGORY_SUCCESS =
  "DELETE_PROJECT_CATEGORY_SUCCESS";
export const DELETE_PROJECT_CATEGORY_FAIL = "DELETE_PROJECT_CATEGORY_FAIL";

export const CLEAR_PROJECT_CATEGORY_MESSAGE_SUCCESS =
  "CLEAR_PROJECT_CATEGORY_MESSAGE_SUCCESS";

//project
export const FETCH_PROJECT_START = "FETCH_PROJECT_START";
export const FETCH_PROJECT_SUCCESS = "FETCH_PROJECT_SUCCESS";
export const FETCH_PROJECT_FAIL = "FETCH_PROJECT_FAIL";

export const FETCH_PROJECTS_START = "FETCH_PROJECTS_START";
export const FETCH_PROJECTS_SUCCESS = "FETCH_PROJECTS_SUCCESS";
export const FETCH_PROJECTS_FAIL = "FETCH_PROJECTS_FAIL";

export const FETCH_FILTERED_PROJECTS_START = "FETCH_FILTERED_PROJECTS_START";
export const FETCH_FILTERED_PROJECTS_SUCCESS =
  "FETCH_FILTERED_PROJECTS_SUCCESS";
export const FETCH_FILTERED_PROJECTS_COUNT_SUCCESS =
  "FETCH_FILTERED_PROJECTS_COUNT_SUCCESS";
export const FETCH_FILTERED_PROJECTS_FAIL = "FETCH_FILTERED_PROJECTS_FAIL";

export const CREATE_PROJECT_START = "CREATE_PROJECT_START";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const CREATE_PROJECT_FAIL = "CREATE_PROJECT_FAIL";

export const UPDATE_PROJECT_START = "UPDATE_PROJECT_START";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_FAIL = "UPDATE_PROJECT_FAIL";

export const DELETE_PROJECT_START = "DELETE_PROJECT_START";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FAIL = "DELETE_PROJECT_FAIL";

export const CLEAR_PROJECT_MESSAGE_SUCCESS = "CLEAR_PROJECT_MESSAGE_SUCCESS";

//request
export const FETCH_REQUEST_START = "FETCH_REQUEST_START";
export const FETCH_REQUEST_SUCCESS = "FETCH_REQUEST_SUCCESS";
export const FETCH_REQUEST_FAIL = "FETCH_REQUEST_FAIL";

export const FETCH_FILTERED_REQUESTS_START = "FETCH_FILTERED_REQUESTS_START";
export const FETCH_FILTERED_REQUESTS_COUNT_SUCCESS =
  "FETCH_FILTERED_REQUESTS_COUNT_SUCCESS";
export const FETCH_FILTERED_REQUESTS_SUCCESS =
  "FETCH_FILTERED_REQUESTS_SUCCESS";
export const FETCH_FILTERED_REQUESTS_FAIL = "FETCH_FILTERED_REQUESTS_FAIL";

export const FETCH_WEEKLY_REQUESTS_START = "FETCH_WEEKLY_REQUESTS_START";
export const FETCH_WEEKLY_REQUESTS_SUCCESS = "FETCH_WEEKLY_REQUESTS_SUCCESS";
export const FETCH_WEEKLY_REQUESTS_COUNT_SUCCESS =
  "FETCH_WEEKLY_REQUESTS_COUNT_SUCCESS";
export const FETCH_WEEKLY_REQUESTS_FAIL = "FETCH_WEEKLY_REQUESTS_FAIL";

export const CREATE_REQUEST_START = "CREATE_REQUEST_START";
export const CREATE_REQUEST_SUCCESS = "CREATE_REQUEST_SUCCESS";
export const CREATE_REQUEST_FAIL = "CREATE_REQUEST_FAIL";

export const UPDATE_REQUEST_START = "UPDATE_REQUEST_START";
export const UPDATE_REQUEST_SUCCESS = "UPDATE_REQUEST_SUCCESS";
export const UPDATE_REQUEST_FAIL = "UPDATE_REQUEST_FAIL";

export const DELETE_REQUEST_START = "DELETE_REQUEST_START";
export const DELETE_REQUEST_SUCCESS = "DELETE_REQUEST_SUCCESS";
export const DELETE_REQUEST_FAIL = "DELETE_REQUEST_FAIL";

export const CLEAR_REQUEST_MESSAGE_SUCCESS = "CLEAR_REQUEST_MESSAGE_SUCCESS";
