import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  projectCategory: null,
  projectCategorySuccess: null,
  projectCategoryError: null,
  projectCategoryLoading: false,

  projectCategories: null,
  projectCategoriesCount: null,
  projectCategoriesSuccess: null,
  projectCategoriesError: null,
  projectCategoriesLoading: false,

  projectCategoriesFiltered: null,
  projectCategoriesFilteredCount: null,
  projectCategoriesFilteredSuccess: null,
  projectCategoriesFilteredError: null,
  projectCategoriesFilteredLoading: false,

  projectCategoryUpdateSuccess: null,
  projectCategoryUpdatError: null,
  projectCategoryUpdateLoading: false,

  projectCategoryDeleteSuccess: null,
  projectCategoryDeleteError: null,
  projectCategoryDeleteLoading: false,
};

//get all categories
const projectCategoriesFetchStart = (state) => {
  return updateObject(state, {
    projectCategoriesSuccess: null,
    projectCategoriesError: null,
    projectCategoriesLoading: true,
  });
};
const projectCategoriesFetchSuccess = (state, action) => {
  return updateObject(state, {
    projectCategories: action.categories,
    projectCategoriesSuccess: true,
    projectCategoriesError: null,
    projectCategoriesLoading: false,
  });
};
const projectCategoriesCountFetchSuccess = (state, action) => {
  return updateObject(state, {
    projectCategoriesCount: action.count,
  });
};
const projectCategoriesFetchFail = (state, action) => {
  return updateObject(state, {
    projectCategoriesError: action.error,
    projectCategoriesSuccess: false,
    projectCategoriesLoading: false,
  });
};

//get filtered category
const projectCategoriesFilteredFetchStart = (state) => {
  return updateObject(state, {
    projectCategoriesFilteredSuccess: null,
    projectCategoriesFilteredError: null,
    projectCategoriesFilteredLoading: true,
  });
};
const projectCategoriesFilteredFetchSuccess = (state, action) => {
  return updateObject(state, {
    projectCategoriesFiltered: action.projectCategories,
    projectCategoriesFilteredSuccess: true,
    projectCategoriesFilteredError: null,
    projectCategoriesFilteredLoading: false,
  });
};
const projectCategoriesFilteredCountFetchSuccess = (state, action) => {
  return updateObject(state, {
    projectCategoriesFilteredCount: action.count,
  });
};
const projectCategoriesFilteredFetchFail = (state, action) => {
  return updateObject(state, {
    projectCategoriesFilteredError: action.error,
    projectCategoriesFilteredSuccess: false,
    projectCategoriesFilteredLoading: false,
  });
};

//get category by Slug
const projectCategoryFetchStart = (state) => {
  return updateObject(state, {
    projectCategorySuccess: null,
    projectCategoryError: null,
    projectCategoryLoading: true,
  });
};
const projectCategoryFetchSuccess = (state, action) => {
  return updateObject(state, {
    projectCategory: action.category,
    projectCategorySuccess: true,
    projectCategoryError: null,
    projectCategoryLoading: false,
  });
};
const projectCategoryFetchFail = (state, action) => {
  return updateObject(state, {
    projectCategoryError: action.error,
    projectCategorySuccess: false,
    projectCategoryLoading: false,
  });
};

//create category
const projectCategoryCreateStart = (state) => {
  return updateObject(state, {
    projectCategorySuccess: null,
    projectCategoryError: null,
    projectCategoryLoading: true,
  });
};
const projectCategoryCreateSuccess = (state) => {
  return updateObject(state, {
    projectCategorySuccess: true,
    projectCategoryError: null,
    projectCategoryLoading: false,
  });
};
const projectCategoryCreateFail = (state, action) => {
  return updateObject(state, {
    projectCategoryError: action.error,
    projectCategorySuccess: false,
    projectCategoryLoading: false,
  });
};

//update category
const projectCategoryUpdateStart = (state) => {
  return updateObject(state, {
    projectCategoryUpdateSuccess: null,
    projectCategoryUpdateError: null,
    projectCategoryUpdateLoading: true,
  });
};
const projectCategoryUpdateSuccess = (state, action) => {
  return updateObject(state, {
    projectCategory: action.category,
    projectCategoryUpdateSuccess: true,
    projectCategoryUpdateError: null,
    projectCategoryUpdateLoading: false,
  });
};
const projectCategoryUpdateFail = (state, action) => {
  return updateObject(state, {
    projectCategoryUpdateError: action.error,
    projectCategoryUpdateSuccess: false,
    projectCategoryUpdateLoading: false,
  });
};

//delete category
const projectCategoryDeleteStart = (state) => {
  return updateObject(state, {
    projectCategoryDeleteSuccess: null,
    projectCategoryDeleteError: null,
    projectCategoryDeleteLoading: true,
  });
};
const projectCategoryDeleteSuccess = (state) => {
  return updateObject(state, {
    projectCategoryDeleteSuccess: true,
    projectCategoryDeleteError: null,
    projectCategoryDeleteLoading: false,
  });
};
const projectCategoryDeleteFail = (state, action) => {
  return updateObject(state, {
    projectCategoryDeleteError: action.error,
    projectCategoryDeleteSuccess: false,
    projectCategoryDeleteLoading: false,
  });
};

//clear category message
const projectCategoryMessageClearSuccess = (state) => {
  return updateObject(state, {
    projectCategorySuccess: null,
    projectCategoryError: null,
    projectCategoriesSuccess: null,
    projectCategoriesError: null,
    projectCategoryUpdateSuccess: null,
    projectCategoryUpdatError: null,
    projectCategoryDeleteSuccess: null,
    projectCategoryDeleteError: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROJECT_CATEGORIES_START:
      return projectCategoriesFetchStart(state);
    case actionTypes.FETCH_PROJECT_CATEGORIES_SUCCESS:
      return projectCategoriesFetchSuccess(state, action);
    case actionTypes.FETCH_PROJECT_CATEGORIES_COUNT_SUCCESS:
      return projectCategoriesCountFetchSuccess(state, action);
    case actionTypes.FETCH_PROJECT_CATEGORIES_FAIL:
      return projectCategoriesFetchFail(state, action);

    case actionTypes.FETCH_PROJECT_CATEGORIES_FILTERED_START:
      return projectCategoriesFilteredFetchStart(state);
    case actionTypes.FETCH_PROJECT_CATEGORIES_FILTERED_SUCCESS:
      return projectCategoriesFilteredFetchSuccess(state, action);
    case actionTypes.FETCH_PROJECT_CATEGORIES_FILTERED_COUNT_SUCCESS:
      return projectCategoriesFilteredCountFetchSuccess(state, action);
    case actionTypes.FETCH_PROJECT_CATEGORIES_FILTERED_FAIL:
      return projectCategoriesFilteredFetchFail(state, action);

    case actionTypes.FETCH_PROJECT_CATEGORY_START:
      return projectCategoryFetchStart(state);
    case actionTypes.FETCH_PROJECT_CATEGORY_SUCCESS:
      return projectCategoryFetchSuccess(state, action);
    case actionTypes.FETCH_PROJECT_CATEGORY_FAIL:
      return projectCategoryFetchFail(state, action);

    case actionTypes.CREATE_PROJECT_CATEGORY_START:
      return projectCategoryCreateStart(state);
    case actionTypes.CREATE_PROJECT_CATEGORY_SUCCESS:
      return projectCategoryCreateSuccess(state);
    case actionTypes.CREATE_PROJECT_CATEGORY_FAIL:
      return projectCategoryCreateFail(state, action);

    case actionTypes.UPDATE_PROJECT_CATEGORY_START:
      return projectCategoryUpdateStart(state);
    case actionTypes.UPDATE_PROJECT_CATEGORY_SUCCESS:
      return projectCategoryUpdateSuccess(state, action);
    case actionTypes.UPDATE_PROJECT_CATEGORY_FAIL:
      return projectCategoryUpdateFail(state, action);

    case actionTypes.DELETE_PROJECT_CATEGORY_START:
      return projectCategoryDeleteStart(state);
    case actionTypes.DELETE_PROJECT_CATEGORY_SUCCESS:
      return projectCategoryDeleteSuccess(state);
    case actionTypes.DELETE_PROJECT_CATEGORY_FAIL:
      return projectCategoryDeleteFail(state, action);

    case actionTypes.CLEAR_PROJECT_CATEGORY_MESSAGE_SUCCESS:
      return projectCategoryMessageClearSuccess(state);

    default:
      return state;
  }
};
export default reducer;
