import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Container, Row, Col } from "reactstrap";
import image from "../../../assets/img/flexcompany-banner.png";

const settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
  autoplay: true,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const data = {
  title: "we process stone",
  content: `Leaders in the stone procesing industry in southwest Macedonia since 2005.`,
  link: "/projects",
  image: image,
};

const Banner = () => {
  return (
    <Container className="mb-4">
      <Row>
        <Col lg={12}>
          <Slider className="banner-slider" {...settings}>
            <Row className="d-flex flex-row justify-content-between m-0">
              <Col
                xs={12}
                lg={6}
                className="d-flex flex-column justify-content-center order-2 order-lg-1"
              >
                <h2 className="text-bold">{data.title}</h2>
                <p>{data.content}</p>
                <div className="banner-button">
                  <Link to={data.link} className="btn-custom danger">
                    View projects
                  </Link>
                </div>
              </Col>
              <Col
                xs={12}
                lg={6}
                className="banner-images mt-4 mt-lg-0 order-1 order-lg-2"
              >
                <img src={data.image} alt="banner" />
              </Col>
            </Row>
          </Slider>
        </Col>
      </Row>
    </Container>
  );
};

export default Banner;
