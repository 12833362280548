import React from "react";
import { Link } from "react-router-dom";

const Mobilemenu = ({ sidemenu, setSidemenu }) => {
  const navigationmenu = [
    {
      id: 1,
      linkText: "home",
      link: "/",
    },
    {
      id: 2,
      linkText: "projects",
      link: "/projects",
    },
    {
      id: 3,
      linkText: "materials",
      link: "/materials",
    },
    {
      id: 4,
      linkText: "blog",
      link: "/blog",
    },
    {
      id: 5,
      linkText: "get a quote",
      link: "/contact",
    },
  ];
  return (
    <ul>
      {navigationmenu.map((item, i) => (
        <li
          key={i}
          className="menu-item"
          onClick={() => {
            item.onClick && item.onClick();
            setSidemenu(!sidemenu);
          }}
        >
          <Link to={item.link}> {item.linkText} </Link>
        </li>
      ))}
    </ul>
  );
};

export default Mobilemenu;
