import React from "react";
import {
  Button,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import * as actions from "../../store/actions/exports";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { IoPersonOutline } from "react-icons/io5";

const renderUserDropdown = (history, userSignout, userLogged) => {
  return (
    <DropdownMenu end className="user-menu">
      {userLogged.user.role === 1 && (
        <Link to={"/dashboard"}>
          <DropdownItem>
            <span className="align-middle">Dashboard</span>
          </DropdownItem>
        </Link>
      )}
      <DropdownItem divider />
      <Link to={"/account-settings"}>
        <DropdownItem>
          <span className="align-middle">Settings</span>
        </DropdownItem>
      </Link>
      <DropdownItem divider />
      <DropdownItem
        onClick={() => {
          userSignout();
          history.push("/");
        }}
      >
        <span className="align-middle">Log out</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

const NavbarUser = ({
  userLogged,
  userLoggedLoading,
  userSignout,
  sidemenu,
  setSidemenu,
}) => {
  const history = useHistory();
  return (
    <ul className="navbar-settings">
      {!userLoggedLoading && userLogged ? (
        <UncontrolledDropdown tag="li">
          <DropdownToggle tag="a" className="navbar-user">
            <span>
              <IoPersonOutline size={23} className="rounded clickable ml-2" />
            </span>
          </DropdownToggle>
          {renderUserDropdown(history, userSignout, userLogged)}
        </UncontrolledDropdown>
      ) : (
        <Link to="/contact" className="d-none d-lg-block btn-custom primary">
          Get a quote
        </Link>
      )}
      <Button
        className="btn-custom ml-2 d-block d-xl-none"
        onClick={() => setSidemenu(!sidemenu)}
      >
        <FaBars size={20} />
      </Button>
    </ul>
  );
};

const mapStateToProps = (state) => {
  return {
    userLogged: state.user.userLogged,
    userLoggedLoading: state.user.userLoggedLoading,
    userSignout: state.user.userSignout,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userSignout: () => dispatch(actions.userSignout()),
    userMessageClear: () => dispatch(actions.userMessageClear()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NavbarUser);
