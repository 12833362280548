import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  materialCategory: null,
  materialCategorySuccess: null,
  materialCategoryError: null,
  materialCategoryLoading: false,

  materialCategories: null,
  materialCategoriesCount: null,
  materialCategoriesSuccess: null,
  materialCategoriesError: null,
  materialCategoriesLoading: false,

  materialCategoriesFiltered: null,
  materialCategoriesFilteredCount: null,
  materialCategoriesFilteredSuccess: null,
  materialCategoriesFilteredError: null,
  materialCategoriesFilteredLoading: false,

  materialCategoryUpdateSuccess: null,
  materialCategoryUpdatError: null,
  materialCategoryUpdateLoading: false,

  materialCategoryDeleteSuccess: null,
  materialCategoryDeleteError: null,
  materialCategoryDeleteLoading: false,
};

//get all categories
const materialCategoriesFetchStart = (state) => {
  return updateObject(state, {
    materialCategoriesSuccess: null,
    materialCategoriesError: null,
    materialCategoriesLoading: true,
  });
};
const materialCategoriesFetchSuccess = (state, action) => {
  return updateObject(state, {
    materialCategories: action.categories,
    materialCategoriesSuccess: true,
    materialCategoriesError: null,
    materialCategoriesLoading: false,
  });
};
const materialCategoriesCountFetchSuccess = (state, action) => {
  return updateObject(state, {
    materialCategoriesCount: action.count,
  });
};
const materialCategoriesFetchFail = (state, action) => {
  return updateObject(state, {
    materialCategoriesError: action.error,
    materialCategoriesSuccess: false,
    materialCategoriesLoading: false,
  });
};

//get filtered category
const materialCategoriesFilteredFetchStart = (state) => {
  return updateObject(state, {
    materialCategoriesFilteredSuccess: null,
    materialCategoriesFilteredError: null,
    materialCategoriesFilteredLoading: true,
  });
};
const materialCategoriesFilteredFetchSuccess = (state, action) => {
  return updateObject(state, {
    materialCategoriesFiltered: action.materialCategories,
    materialCategoriesFilteredSuccess: true,
    materialCategoriesFilteredError: null,
    materialCategoriesFilteredLoading: false,
  });
};
const materialCategoriesFilteredCountFetchSuccess = (state, action) => {
  return updateObject(state, {
    materialCategoriesFilteredCount: action.count,
  });
};
const materialCategoriesFilteredFetchFail = (state, action) => {
  return updateObject(state, {
    materialCategoriesFilteredError: action.error,
    materialCategoriesFilteredSuccess: false,
    materialCategoriesFilteredLoading: false,
  });
};

//get category by Name
const materialCategoryFetchStart = (state) => {
  return updateObject(state, {
    materialCategorySuccess: null,
    materialCategoryError: null,
    materialCategoryLoading: true,
  });
};
const materialCategoryFetchSuccess = (state, action) => {
  return updateObject(state, {
    materialCategory: action.category,
    materialCategorySuccess: true,
    materialCategoryError: null,
    materialCategoryLoading: false,
  });
};
const materialCategoryFetchFail = (state, action) => {
  return updateObject(state, {
    materialCategoryError: action.error,
    materialCategorySuccess: false,
    materialCategoryLoading: false,
  });
};

//create category
const materialCategoryCreateStart = (state) => {
  return updateObject(state, {
    materialCategorySuccess: null,
    materialCategoryError: null,
    materialCategoryLoading: true,
  });
};
const materialCategoryCreateSuccess = (state) => {
  return updateObject(state, {
    materialCategorySuccess: true,
    materialCategoryError: null,
    materialCategoryLoading: false,
  });
};
const materialCategoryCreateFail = (state, action) => {
  return updateObject(state, {
    materialCategoryError: action.error,
    materialCategorySuccess: false,
    materialCategoryLoading: false,
  });
};

//update category
const materialCategoryUpdateStart = (state) => {
  return updateObject(state, {
    materialCategoryUpdateSuccess: null,
    materialCategoryUpdateError: null,
    materialCategoryUpdateLoading: true,
  });
};
const materialCategoryUpdateSuccess = (state, action) => {
  return updateObject(state, {
    materialCategory: action.category,
    materialCategoryUpdateSuccess: true,
    materialCategoryUpdateError: null,
    materialCategoryUpdateLoading: false,
  });
};
const materialCategoryUpdateFail = (state, action) => {
  return updateObject(state, {
    materialCategoryUpdateError: action.error,
    materialCategoryUpdateSuccess: false,
    materialCategoryUpdateLoading: false,
  });
};

//delete category
const materialCategoryDeleteStart = (state) => {
  return updateObject(state, {
    materialCategoryDeleteSuccess: null,
    materialCategoryDeleteError: null,
    materialCategoryDeleteLoading: true,
  });
};
const materialCategoryDeleteSuccess = (state) => {
  return updateObject(state, {
    materialCategoryDeleteSuccess: true,
    materialCategoryDeleteError: null,
    materialCategoryDeleteLoading: false,
  });
};
const materialCategoryDeleteFail = (state, action) => {
  return updateObject(state, {
    materialCategoryDeleteError: action.error,
    materialCategoryDeleteSuccess: false,
    materialCategoryDeleteLoading: false,
  });
};

//clear category message
const materialCategoryMessageClearSuccess = (state) => {
  return updateObject(state, {
    materialCategorySuccess: null,
    materialCategoryError: null,
    materialCategoriesSuccess: null,
    materialCategoriesError: null,
    materialCategoryUpdateSuccess: null,
    materialCategoryUpdatError: null,
    materialCategoryDeleteSuccess: null,
    materialCategoryDeleteError: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MATERIAL_CATEGORIES_START:
      return materialCategoriesFetchStart(state);
    case actionTypes.FETCH_MATERIAL_CATEGORIES_SUCCESS:
      return materialCategoriesFetchSuccess(state, action);
    case actionTypes.FETCH_MATERIAL_CATEGORIES_COUNT_SUCCESS:
      return materialCategoriesCountFetchSuccess(state, action);
    case actionTypes.FETCH_MATERIAL_CATEGORIES_FAIL:
      return materialCategoriesFetchFail(state, action);

    case actionTypes.FETCH_MATERIAL_CATEGORIES_FILTERED_START:
      return materialCategoriesFilteredFetchStart(state);
    case actionTypes.FETCH_MATERIAL_CATEGORIES_FILTERED_SUCCESS:
      return materialCategoriesFilteredFetchSuccess(state, action);
    case actionTypes.FETCH_MATERIAL_CATEGORIES_FILTERED_COUNT_SUCCESS:
      return materialCategoriesFilteredCountFetchSuccess(state, action);
    case actionTypes.FETCH_MATERIAL_CATEGORIES_FILTERED_FAIL:
      return materialCategoriesFilteredFetchFail(state, action);

    case actionTypes.FETCH_MATERIAL_CATEGORY_START:
      return materialCategoryFetchStart(state);
    case actionTypes.FETCH_MATERIAL_CATEGORY_SUCCESS:
      return materialCategoryFetchSuccess(state, action);
    case actionTypes.FETCH_MATERIAL_CATEGORY_FAIL:
      return materialCategoryFetchFail(state, action);

    case actionTypes.CREATE_MATERIAL_CATEGORY_START:
      return materialCategoryCreateStart(state);
    case actionTypes.CREATE_MATERIAL_CATEGORY_SUCCESS:
      return materialCategoryCreateSuccess(state);
    case actionTypes.CREATE_MATERIAL_CATEGORY_FAIL:
      return materialCategoryCreateFail(state, action);

    case actionTypes.UPDATE_MATERIAL_CATEGORY_START:
      return materialCategoryUpdateStart(state);
    case actionTypes.UPDATE_MATERIAL_CATEGORY_SUCCESS:
      return materialCategoryUpdateSuccess(state, action);
    case actionTypes.UPDATE_MATERIAL_CATEGORY_FAIL:
      return materialCategoryUpdateFail(state, action);

    case actionTypes.DELETE_MATERIAL_CATEGORY_START:
      return materialCategoryDeleteStart(state);
    case actionTypes.DELETE_MATERIAL_CATEGORY_SUCCESS:
      return materialCategoryDeleteSuccess(state);
    case actionTypes.DELETE_MATERIAL_CATEGORY_FAIL:
      return materialCategoryDeleteFail(state, action);

    case actionTypes.CLEAR_MATERIAL_CATEGORY_MESSAGE_SUCCESS:
      return materialCategoryMessageClearSuccess(state);

    default:
      return state;
  }
};
export default reducer;
