import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  project: null,
  projectSuccess: null,
  projectError: null,
  projectLoading: false,

  projects: null,
  projectsSuccess: null,
  projectsError: null,
  projectsLoading: false,

  projectsFiltered: null,
  projectsFilteredCount: null,
  projectsFilteredSuccess: null,
  projectsFilteredError: null,
  projectsFilteredLoading: false,

  projectUpdateSuccess: null,
  projectUpdateError: null,
  projectUpdateLoading: false,

  projectDeleteSuccess: null,
  projectDeleteError: null,
  projectDeleteLoading: false,
};

//get all projects
const projectsFetchStart = (state) => {
  return updateObject(state, {
    projectsSuccess: null,
    projectsError: null,
    projectsLoading: true,
  });
};
const projectsFetchSuccess = (state, action) => {
  return updateObject(state, {
    projects: action.projects,
    projectsSuccess: true,
    projectsError: null,
    projectsLoading: false,
  });
};
const projectsFetchFail = (state, action) => {
  return updateObject(state, {
    projectsError: action.error,
    projectsSuccess: false,
    projectsLoading: false,
  });
};

//get filtered projects
const projectsFilteredFetchStart = (state) => {
  return updateObject(state, {
    projectsFilteredSuccess: null,
    projectsFilteredError: null,
    projectsFilteredLoading: true,
  });
};
const projectsFilteredFetchSuccess = (state, action) => {
  return updateObject(state, {
    projectsFiltered: action.projects,
    projectsFilteredSuccess: true,
    projectsFilteredError: null,
    projectsFilteredLoading: false,
  });
};
const projectsFilteredCountFetchSuccess = (state, action) => {
  return updateObject(state, {
    projectsFilteredCount: action.count,
  });
};
const projectsFilteredFetchFail = (state, action) => {
  return updateObject(state, {
    projectsFilteredError: action.error,
    projectsFilteredSuccess: false,
    projectsFilteredLoading: false,
  });
};

//get project by slug
const projectFetchStart = (state) => {
  return updateObject(state, {
    projectSuccess: null,
    projectError: null,
    projectLoading: true,
  });
};
const projectFetchSuccess = (state, action) => {
  return updateObject(state, {
    project: action.project,
    projectSuccess: true,
    projectError: null,
    projectLoading: false,
  });
};
const projectFetchFail = (state, action) => {
  return updateObject(state, {
    projectError: action.error,
    projectSuccess: false,
    projectLoading: false,
  });
};

//create project
const projectCreateStart = (state) => {
  return updateObject(state, {
    projectSuccess: null,
    projectError: null,
    projectLoading: true,
  });
};
const projectCreateSuccess = (state) => {
  return updateObject(state, {
    projectSuccess: true,
    projectError: null,
    projectLoading: false,
  });
};
const projectCreateFail = (state, action) => {
  return updateObject(state, {
    projectError: action.error,
    projectSuccess: false,
    projectLoading: false,
  });
};

//update project
const projectUpdateStart = (state) => {
  return updateObject(state, {
    projectUpdateSuccess: null,
    projectUpdateError: null,
    projectUpdateLoading: true,
  });
};
const projectUpdateSuccess = (state, action) => {
  return updateObject(state, {
    project: action.project,
    projectUpdateSuccess: true,
    projectUpdateError: null,
    projectUpdateLoading: false,
  });
};
const projectUpdateFail = (state, action) => {
  return updateObject(state, {
    projectUpdateError: action.error,
    projectUpdateSuccess: false,
    projectUpdateLoading: false,
  });
};

//delete project
const projectDeleteStart = (state) => {
  return updateObject(state, {
    projectDeleteSuccess: null,
    projectDeleteError: null,
    projectDeleteLoading: true,
  });
};
const projectDeleteSuccess = (state) => {
  return updateObject(state, {
    projectDeleteSuccess: true,
    projectDeleteError: null,
    projectDeleteLoading: false,
  });
};
const projectDeleteFail = (state, action) => {
  return updateObject(state, {
    projectDeleteError: action.error,
    projectDeleteSuccess: false,
    projectDeleteLoading: false,
  });
};

//clear project message
const projectMessageClearSuccess = (state) => {
  return updateObject(state, {
    projectSuccess: null,
    projectError: null,
    projectsSuccess: null,
    projectsError: null,
    projectsFilteredSuccess: null,
    projectsFilteredError: null,
    projectUpdateSuccess: null,
    projectUpdateError: null,
    projectDeleteSuccess: null,
    projectDeleteError: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROJECT_START:
      return projectFetchStart(state);
    case actionTypes.FETCH_PROJECT_SUCCESS:
      return projectFetchSuccess(state, action);
    case actionTypes.FETCH_PROJECT_FAIL:
      return projectFetchFail(state, action);

    case actionTypes.FETCH_PROJECTS_START:
      return projectsFetchStart(state);
    case actionTypes.FETCH_PROJECTS_SUCCESS:
      return projectsFetchSuccess(state, action);
    case actionTypes.FETCH_PROJECTS_FAIL:
      return projectsFetchFail(state, action);

    case actionTypes.FETCH_FILTERED_PROJECTS_START:
      return projectsFilteredFetchStart(state);
    case actionTypes.FETCH_FILTERED_PROJECTS_SUCCESS:
      return projectsFilteredFetchSuccess(state, action);
    case actionTypes.FETCH_FILTERED_PROJECTS_COUNT_SUCCESS:
      return projectsFilteredCountFetchSuccess(state, action);
    case actionTypes.FETCH_FILTERED_PROJECTS_FAIL:
      return projectsFilteredFetchFail(state, action);

    case actionTypes.CREATE_PROJECT_START:
      return projectCreateStart(state);
    case actionTypes.CREATE_PROJECT_SUCCESS:
      return projectCreateSuccess(state);
    case actionTypes.CREATE_PROJECT_FAIL:
      return projectCreateFail(state, action);

    case actionTypes.UPDATE_PROJECT_START:
      return projectUpdateStart(state);
    case actionTypes.UPDATE_PROJECT_SUCCESS:
      return projectUpdateSuccess(state, action);
    case actionTypes.UPDATE_PROJECT_FAIL:
      return projectUpdateFail(state, action);

    case actionTypes.DELETE_PROJECT_START:
      return projectDeleteStart(state);
    case actionTypes.DELETE_PROJECT_SUCCESS:
      return projectDeleteSuccess(state);
    case actionTypes.DELETE_PROJECT_FAIL:
      return projectDeleteFail(state, action);

    case actionTypes.CLEAR_PROJECT_MESSAGE_SUCCESS:
      return projectMessageClearSuccess(state);

    default:
      return state;
  }
};
export default reducer;
