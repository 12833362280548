import { combineReducers } from "redux";

import userReducer from "./userReducer";
import contactReducer from "./contactReducer";
import mainBannerReducer from "./mainBannersReducer";
import blogPostReducer from "../reducers/blogPostReducer";
import blogCategoryReducer from "../reducers/blogCategoryReducer";
import imageReducer from "../reducers/imageReducer";
import materialCategoryReducer from "../reducers/materialCategoryReducer";
import materialReducer from "../reducers/materialReducer";
import projectCategoryReducer from "../reducers/projectCategoryReducer";
import projectReducer from "../reducers/projectReducer";
import requestReducer from "../reducers/requestReducer";

const rootReducer = combineReducers({
  user: userReducer,
  contact: contactReducer,
  mainBanner: mainBannerReducer,
  blogPost: blogPostReducer,
  blogCategory: blogCategoryReducer,
  image: imageReducer,
  materialCategory: materialCategoryReducer,
  material: materialReducer,
  projectCategory: projectCategoryReducer,
  project: projectReducer,
  request: requestReducer,
});

export default rootReducer;
