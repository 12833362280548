import React from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";

const Error = () => {
  return (
    <Container className="my-4 h-50">
      <div className="page-not-found">
        <h1 className="large-text">404</h1>
        <h4>Page not found</h4>
        <p>
          The page you are trying to access could not be found. Please try
          looking for something else
        </p>
        <div className="button-container">
          <Link to="/" className="btn-custom primary">
            Go To Home Page
          </Link>
        </div>
      </div>
    </Container>
  );
};

export default Error;
