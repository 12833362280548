import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  image: null,
  imageSuccess: null,
  imageError: null,
  imageLoading: false,

  imagesFiltered: null,
  imagesFilteredCount: null,
  imagesFilteredSuccess: null,
  imagesFilteredError: null,
  imagesFilteredLoading: false,

  imageDeleteSuccess: null,
  imageDeleteError: null,
  imageDeleteLoading: false,
};

//get filtered images
const imagesFilteredFetchStart = (state) => {
  return updateObject(state, {
    imagesFilteredSuccess: null,
    imagesFilteredError: null,
    imagesFilteredLoading: true,
  });
};
const imagesFilteredFetchSuccess = (state, action) => {
  return updateObject(state, {
    imagesFiltered: action.images,
    imagesFilteredSuccess: true,
    imagesFilteredError: null,
    imagesFilteredLoading: false,
  });
};
const imagesFilteredCountFetchSuccess = (state, action) => {
  return updateObject(state, {
    imagesFilteredCount: action.count,
  });
};
const imagesFilteredFetchFail = (state, action) => {
  return updateObject(state, {
    imagesFilteredError: action.error,
    imagesFilteredSuccess: false,
    imagesFilteredLoading: false,
  });
};

//upload image
const uploadImageStart = (state) => {
  return updateObject(state, {
    imageSuccess: null,
    imageError: null,
    imageLoading: true,
  });
};
const uploadImageSuccess = (state, action) => {
  return updateObject(state, {
    image: action.image,
    imageSuccess: true,
    imageError: null,
    imageLoading: false,
  });
};
const uploadImageFail = (state, action) => {
  return updateObject(state, {
    imageSuccess: false,
    imageError: action.error,
    imageLoading: false,
  });
};

//delete image
const imageDeleteStart = (state) => {
  return updateObject(state, {
    imageDeleteSuccess: null,
    imageDeleteError: null,
    imageDeleteLoading: true,
  });
};
const imageDeleteSuccess = (state, action) => {
  return updateObject(state, {
    image: action.image,
    imageDeleteSuccess: true,
    imageDeleteError: null,
    imageDeleteLoading: false,
  });
};
const imageDeleteFail = (state, action) => {
  return updateObject(state, {
    imageDeleteError: action.error,
    imageDeleteSuccess: false,
    imageDeleteLoading: false,
  });
};

//clear upload message
const uploadImageMessageClearSuccess = (state) => {
  return updateObject(state, {
    imageSuccess: null,
    imageError: null,
    imageDeleteSuccess: null,
    imageDeleteError: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_FILTERED_IMAGES_START:
      return imagesFilteredFetchStart(state);
    case actionTypes.FETCH_FILTERED_IMAGES_SUCCESS:
      return imagesFilteredFetchSuccess(state, action);
    case actionTypes.FETCH_FILTERED_IMAGES_COUNT_SUCCESS:
      return imagesFilteredCountFetchSuccess(state, action);
    case actionTypes.FETCH_FILTERED_IMAGES_FAIL:
      return imagesFilteredFetchFail(state, action);

    case actionTypes.UPLOAD_IMAGE_START:
      return uploadImageStart(state);
    case actionTypes.UPLOAD_IMAGE_SUCCESS:
      return uploadImageSuccess(state, action);
    case actionTypes.UPLOAD_IMAGE_FAIL:
      return uploadImageFail(state, action);

    case actionTypes.DELETE_IMAGE_START:
      return imageDeleteStart(state);
    case actionTypes.DELETE_IMAGE_SUCCESS:
      return imageDeleteSuccess(state, action);
    case actionTypes.DELETE_IMAGE_FAIL:
      return imageDeleteFail(state, action);

    case actionTypes.CLEAR_UPLOAD_IMAGE_MESSAGE_SUCCESS:
      return uploadImageMessageClearSuccess(state);
    default:
      return state;
  }
};
export default reducer;
