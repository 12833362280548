import * as actionTypes from "./actionTypes";
import { API } from "../config";

//get filtered images
export const imagesFilteredFetchStart = () => {
  return {
    type: actionTypes.FETCH_FILTERED_IMAGES_START,
  };
};
export const imagesFilteredFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FILTERED_IMAGES_SUCCESS,
    images: data,
  };
};
export const imagesFilteredCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FILTERED_IMAGES_COUNT_SUCCESS,
    count: data,
  };
};
export const imagesFilteredFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_FILTERED_IMAGES_FAIL,
    error: error,
  };
};
export const imagesFilteredFetch = (userId, token, skip, limit, filters) => {
  const data = {
    limit,
    skip,
    filters,
  };
  return (dispatch) => {
    dispatch(imagesFilteredFetchStart());
    fetch(`${API}/images/filtered/count/${userId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ filters: filters }),
    })
      .then((response) => response.json())
      .then((result) => {
        let imagesCount = result.count;
        fetch(`${API}/images/filtered/${userId}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(imagesFilteredFetchFail(data.error));
            } else {
              dispatch(imagesFilteredFetchSuccess(data.images));
              dispatch(imagesFilteredCountFetchSuccess(imagesCount));
            }
          })
          .catch((error) => {
            dispatch(imagesFilteredFetchFail(error));
          });
      });
  };
};

//upload image
export const uploadImageStart = () => {
  return {
    type: actionTypes.UPLOAD_IMAGE_START,
  };
};
export const uploadImageSuccess = (data) => {
  return {
    type: actionTypes.UPLOAD_IMAGE_SUCCESS,
    image: data,
  };
};
export const uploadImageFail = (error) => {
  return {
    type: actionTypes.UPLOAD_IMAGE_FAIL,
    error: error,
  };
};
export const uploadImage = (userId, token, imageData) => {
  let formData = new FormData();
  formData.set("image", imageData);
  return (dispatch) => {
    dispatch(uploadImageStart());
    return new Promise((resolve, reject) => {
      fetch(`${API}/uploadImage/${userId}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            dispatch(uploadImageFail(data.error));
            reject({ error: data.error });
          } else {
            dispatch(uploadImageSuccess(data));
            resolve({ data: { link: `${API}/${data}` } });
          }
        })
        .catch((error) => {
          dispatch(uploadImageFail(error));
          reject({ error: error });
        });
    });
  };
};

//delete image
export const imageDeleteStart = () => {
  return {
    type: actionTypes.DELETE_IMAGE_START,
  };
};
export const imageDeleteSuccess = (data) => {
  return {
    type: actionTypes.DELETE_IMAGE_SUCCESS,
    image: data,
  };
};
export const imageDeleteFail = (error) => {
  return {
    type: actionTypes.DELETE_IMAGE_FAIL,
    error: error,
  };
};
export const imageDelete = (imageId, userId, token) => {
  return (dispatch) => {
    dispatch(imageDeleteStart());
    fetch(`${API}/image/${userId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ imageId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(imageDeleteFail(data.error));
        } else {
          dispatch(imageDeleteSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(imageDeleteFail(error));
      });
  };
};

//clear upload image message
export const uploadImageMessageClearSuccess = () => {
  return {
    type: actionTypes.CLEAR_UPLOAD_IMAGE_MESSAGE_SUCCESS,
  };
};
export const uploadImageMessageClear = () => {
  return (dispatch) => {
    dispatch(uploadImageMessageClearSuccess());
  };
};
