import React from "react";
import About from "../sections/Home/About";
import Banner from "../sections/Home/Banner";
import Projects from "../sections/Home/Projects";
// import Blog from "../sections/Home/Blog";

const HomePage = () => {
  return (
    <React.Fragment>
      <Banner />
      <About />
      <Projects />
    </React.Fragment>
  );
};

export default HomePage;
