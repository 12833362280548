import * as actionTypes from "./actionTypes";
import { API } from "../config";

//get filtered requests
export const requestsFilteredFetchStart = () => {
  return {
    type: actionTypes.FETCH_FILTERED_REQUESTS_START,
  };
};
export const requestsFilteredFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FILTERED_REQUESTS_SUCCESS,
    requests: data,
  };
};
export const requestsFilteredCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FILTERED_REQUESTS_COUNT_SUCCESS,
    count: data,
  };
};
export const requestsFilteredFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_FILTERED_REQUESTS_FAIL,
    error: error,
  };
};
export const requestsFilteredFetch = (skip, limit, filters) => {
  const data = {
    limit,
    skip,
    filters,
  };
  return (dispatch) => {
    dispatch(requestsFilteredFetchStart());
    fetch(`${API}/requests/filtered/count`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        let requestsCount = result.count;
        fetch(`${API}/requests/filtered`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(requestsFilteredFetchFail(data.error));
            } else {
              dispatch(requestsFilteredFetchSuccess(data));
              dispatch(requestsFilteredCountFetchSuccess(requestsCount));
            }
          })
          .catch((error) => {
            dispatch(requestsFilteredFetchFail(error));
          });
      });
  };
};

// get weekly requests
export const requestsWeeklyFetchStart = () => {
  return {
    type: actionTypes.FETCH_WEEKLY_REQUESTS_START,
  };
};
export const requestsWeeklyFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_WEEKLY_REQUESTS_SUCCESS,
    requestsWeekly: data,
  };
};
export const requestsWeeklyCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_WEEKLY_REQUESTS_COUNT_SUCCESS,
    count: data,
  };
};
export const requestsWeeklyFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_WEEKLY_REQUESTS_FAIL,
    error: error,
  };
};
export const requestsWeeklyFetch = (userId, token, weekDates, skip, limit) => {
  return (dispatch) => {
    dispatch(requestsWeeklyFetchStart());
    fetch(`${API}/requests/countWeekly/${userId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ weekDates }),
    })
      .then((response) => response.json())
      .then((result) => {
        let ordersCount = result.count;
        fetch(`${API}/requests/listWeekly/${userId}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ weekDates, skip, limit }),
        })
          .then((response) => response.json())
          .then((data) => {
            dispatch(requestsWeeklyFetchSuccess(data));
            dispatch(requestsWeeklyCountFetchSuccess(ordersCount));
          })
          .catch((err) => {
            dispatch(requestsWeeklyFetchFail(err));
          });
      });
  };
};

//get request by ID
export const requestFetchStart = () => {
  return {
    type: actionTypes.FETCH_REQUEST_START,
  };
};
export const requestFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_REQUEST_SUCCESS,
    request: data,
  };
};
export const requestFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_REQUEST_FAIL,
    error: error,
  };
};
export const requestFetch = (requestSlug) => {
  return (dispatch) => {
    dispatch(requestFetchStart());
    fetch(`${API}/request/${requestSlug}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(requestFetchFail(data.error));
        } else {
          dispatch(requestFetchSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(requestFetchFail(error));
      });
  };
};

//create request
export const requestCreateStart = () => {
  return {
    type: actionTypes.CREATE_REQUEST_START,
  };
};
export const requestCreateSuccess = () => {
  return {
    type: actionTypes.CREATE_REQUEST_SUCCESS,
  };
};
export const requestCreateFail = (error) => {
  return {
    type: actionTypes.CREATE_REQUEST_FAIL,
    error: error,
  };
};
export const requestCreate = (requestData) => {
  return (dispatch) => {
    dispatch(requestCreateStart());
    fetch(`${API}/request/create`, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: requestData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(requestCreateFail(data.error));
        } else {
          dispatch(requestCreateSuccess());
        }
      })
      .catch((error) => {
        dispatch(requestCreateFail(error));
      });
  };
};

//update request
export const requestUpdateStart = () => {
  return {
    type: actionTypes.UPDATE_REQUEST_START,
  };
};
export const requestUpdateSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_REQUEST_SUCCESS,
    request: data,
  };
};
export const requestUpdateFail = (error) => {
  return {
    type: actionTypes.UPDATE_REQUEST_FAIL,
    error: error,
  };
};
export const requestUpdate = (requestId, userId, token, requestData) => {
  return (dispatch) => {
    dispatch(requestUpdateStart());
    fetch(`${API}/request/update/${requestId}/${userId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ status: requestData }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(requestUpdateFail(data.error));
        } else {
          dispatch(requestUpdateSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(requestUpdateFail(error));
      });
  };
};

//delete request
export const requestDeleteStart = () => {
  return {
    type: actionTypes.DELETE_REQUEST_START,
  };
};
export const requestDeleteSuccess = () => {
  return {
    type: actionTypes.DELETE_REQUEST_SUCCESS,
  };
};
export const requestDeleteFail = (error) => {
  return {
    type: actionTypes.DELETE_REQUEST_FAIL,
    error: error,
  };
};
export const requestDelete = (requestId, userId, token) => {
  return (dispatch) => {
    dispatch(requestDeleteStart());
    fetch(`${API}/request/${requestId}/${userId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(requestDeleteFail(data.error));
        } else {
          dispatch(requestDeleteSuccess());
        }
      })
      .catch((err) => {
        dispatch(requestDeleteFail(err));
      });
  };
};

//clear request message
export const requestMessageClearSuccess = () => {
  return {
    type: actionTypes.CLEAR_REQUEST_MESSAGE_SUCCESS,
  };
};
export const requestMessageClear = () => {
  return (dispatch) => {
    dispatch(requestMessageClearSuccess());
  };
};
