import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  request: null,
  requestSuccess: null,
  requestError: null,
  requestLoading: false,

  requestsWeekly: null,
  requestsWeeklyCount: null,
  requestsWeeklySuccess: null,
  requestsWeeklyError: null,
  requestsWeeklyLoading: false,

  requestsFiltered: null,
  requestsFilteredCount: null,
  requestsFilteredSuccess: null,
  requestsFilteredError: null,
  requestsFilteredLoading: false,

  requestUpdateSuccess: null,
  requestUpdateError: null,
  requestUpdateLoading: false,

  requestDeleteSuccess: null,
  requestDeleteError: null,
  requestDeleteLoading: false,
};

//get weekly requests
const requestsWeeklyFetchStart = (state) => {
  return updateObject(state, {
    requestsWeeklySuccess: null,
    requestsWeeklyError: null,
    requestsWeeklyLoading: true,
  });
};
const requestsWeeklyFetchSuccess = (state, action) => {
  return updateObject(state, {
    requestsWeekly: action.requestsWeekly,
    requestsWeeklySuccess: true,
    requestsWeeklyError: null,
    requestsWeeklyLoading: false,
  });
};
const requestsWeeklyCountFetchSuccess = (state, action) => {
  return updateObject(state, {
    requestsWeeklyCount: action.count,
  });
};
const requestsWeeklyFetchFail = (state, action) => {
  return updateObject(state, {
    requestsWeeklyError: action.error,
    requestsWeeklySuccess: false,
    requestsWeeklyLoading: false,
  });
};

//get filtered requests
const requestsFilteredFetchStart = (state) => {
  return updateObject(state, {
    requestsFilteredSuccess: null,
    requestsFilteredError: null,
    requestsFilteredLoading: true,
  });
};
const requestsFilteredFetchSuccess = (state, action) => {
  return updateObject(state, {
    requestsFiltered: action.requests,
    requestsFilteredSuccess: true,
    requestsFilteredError: null,
    requestsFilteredLoading: false,
  });
};
const requestsFilteredCountFetchSuccess = (state, action) => {
  return updateObject(state, {
    requestsFilteredCount: action.count,
  });
};
const requestsFilteredFetchFail = (state, action) => {
  return updateObject(state, {
    requestsFilteredError: action.error,
    requestsFilteredSuccess: false,
    requestsFilteredLoading: false,
  });
};

//get request by ID
const requestFetchStart = (state) => {
  return updateObject(state, {
    requestSuccess: null,
    requestError: null,
    requestLoading: true,
  });
};
const requestFetchSuccess = (state, action) => {
  return updateObject(state, {
    request: action.request,
    requestSuccess: true,
    requestError: null,
    requestLoading: false,
  });
};
const requestFetchFail = (state, action) => {
  return updateObject(state, {
    requestError: action.error,
    requestSuccess: false,
    requestLoading: false,
  });
};

//request create
const requestCreateStart = (state) => {
  return updateObject(state, {
    requestSuccess: null,
    requestError: null,
    requestLoading: true,
  });
};
const requestCreateSuccess = (state, action) => {
  return updateObject(state, {
    request: action.request,
    requestSuccess: true,
    requestError: null,
    requestLoading: false,
  });
};
const requestCreateFail = (state, action) => {
  return updateObject(state, {
    requestError: action.error,
    requestSuccess: false,
    requestLoading: false,
  });
};

//request update
const requestUpdateStart = (state) => {
  return updateObject(state, {
    requestUpdateSuccess: null,
    requestUpdateError: null,
    requestUpdateLoading: true,
  });
};
const requestUpdateSuccess = (state, action) => {
  return updateObject(state, {
    request: action.request,
    requestUpdateSuccess: true,
    requestUpdateError: null,
    requestUpdateLoading: false,
  });
};
const requestUpdateFail = (state, action) => {
  return updateObject(state, {
    requestUpdateError: action.error,
    requestUpdateSuccess: false,
    requestUpdateLoading: false,
  });
};

//request delete
const requestDeleteStart = (state) => {
  return updateObject(state, {
    requestDeleteSuccess: null,
    requestDeleteError: null,
    requestDeleteLoading: true,
  });
};
const requestDeleteSuccess = (state, action) => {
  return updateObject(state, {
    request: action.request,
    requestDeleteSuccess: true,
    requestDeleteError: null,
    requestDeleteLoading: false,
  });
};
const requestDeleteFail = (state, action) => {
  return updateObject(state, {
    requestDeleteError: action.error,
    requestDeleteSuccess: false,
    requestDeleteLoading: false,
  });
};

//request message clear
const requestMessageClearSuccess = (state) => {
  return updateObject(state, {
    requestSuccess: null,
    requestError: null,
    requestsSuccess: null,
    requestsError: null,
    requestsWeeklySuccess: null,
    requestsWeeklyError: null,
    requestUpdateSuccess: null,
    requestUpdateError: null,
    requestDeleteSuccess: null,
    requestDeleteError: null,
    requestsFiltered: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_REQUEST_START:
      return requestFetchStart(state);
    case actionTypes.FETCH_REQUEST_SUCCESS:
      return requestFetchSuccess(state, action);
    case actionTypes.FETCH_REQUEST_FAIL:
      return requestFetchFail(state, action);

    case actionTypes.FETCH_WEEKLY_REQUESTS_START:
      return requestsWeeklyFetchStart(state);
    case actionTypes.FETCH_WEEKLY_REQUESTS_SUCCESS:
      return requestsWeeklyFetchSuccess(state, action);
    case actionTypes.FETCH_WEEKLY_REQUESTS_COUNT_SUCCESS:
      return requestsWeeklyCountFetchSuccess(state, action);
    case actionTypes.FETCH_WEEKLY_REQUESTS_FAIL:
      return requestsWeeklyFetchFail(state, action);

    case actionTypes.FETCH_FILTERED_REQUESTS_START:
      return requestsFilteredFetchStart(state);
    case actionTypes.FETCH_FILTERED_REQUESTS_SUCCESS:
      return requestsFilteredFetchSuccess(state, action);
    case actionTypes.FETCH_FILTERED_REQUESTS_COUNT_SUCCESS:
      return requestsFilteredCountFetchSuccess(state, action);
    case actionTypes.FETCH_FILTERED_REQUESTS_FAIL:
      return requestsFilteredFetchFail(state, action);

    case actionTypes.CREATE_REQUEST_START:
      return requestCreateStart(state);
    case actionTypes.CREATE_REQUEST_SUCCESS:
      return requestCreateSuccess(state, action);
    case actionTypes.CREATE_REQUEST_FAIL:
      return requestCreateFail(state, action);

    case actionTypes.UPDATE_REQUEST_START:
      return requestUpdateStart(state);
    case actionTypes.UPDATE_REQUEST_SUCCESS:
      return requestUpdateSuccess(state, action);
    case actionTypes.UPDATE_REQUEST_FAIL:
      return requestUpdateFail(state, action);

    case actionTypes.DELETE_REQUEST_START:
      return requestDeleteStart(state);
    case actionTypes.DELETE_REQUEST_SUCCESS:
      return requestDeleteSuccess(state, action);
    case actionTypes.DELETE_REQUEST_FAIL:
      return requestDeleteFail(state, action);

    case actionTypes.CLEAR_REQUEST_MESSAGE_SUCCESS:
      return requestMessageClearSuccess(state);

    default:
      return state;
  }
};
export default reducer;
