import * as actionTypes from "./actionTypes";
import { API } from "../config";

//get all projects
export const projectsFetchStart = () => {
  return {
    type: actionTypes.FETCH_PROJECTS_START,
  };
};
export const projectsFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PROJECTS_SUCCESS,
    projects: data,
  };
};
export const projectsCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FILTERED_PROJECTS_COUNT_SUCCESS,
    count: data,
  };
};
export const projectsFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_PROJECTS_FAIL,
    error: error,
  };
};
export const projectsFetch = (skip, limit, filters) => {
  const data = {
    limit,
    skip,
    filters,
  };
  return (dispatch) => {
    dispatch(projectsFetchStart());
    fetch(`${API}/projects/count`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filters: filters }),
    })
      .then((response) => response.json())
      .then((result) => {
        let projectsCount = result.count;
        fetch(`${API}/projects`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(projectsFetchFail(data.error));
            } else {
              dispatch(projectsFetchSuccess(data.projects));
              dispatch(projectsCountFetchSuccess(projectsCount));
            }
          })
          .catch((error) => {
            dispatch(projectsFetchFail(error));
          });
      });
  };
};

//get filtered projects
export const projectsFilteredFetchStart = () => {
  return {
    type: actionTypes.FETCH_FILTERED_PROJECTS_START,
  };
};
export const projectsFilteredFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FILTERED_PROJECTS_SUCCESS,
    projects: data,
  };
};
export const projectsFilteredCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FILTERED_PROJECTS_COUNT_SUCCESS,
    count: data,
  };
};
export const projectsFilteredFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_FILTERED_PROJECTS_FAIL,
    error: error,
  };
};
export const projectsFilteredFetch = (skip, limit, filters) => {
  const data = {
    limit,
    skip,
    filters,
  };
  return (dispatch) => {
    dispatch(projectsFilteredFetchStart());
    fetch(`${API}/projects/filtered/count`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filters: filters }),
    })
      .then((response) => response.json())
      .then((result) => {
        let projectsCount = result.count;
        fetch(`${API}/projects/filtered`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(projectsFilteredFetchFail(data.error));
            } else {
              dispatch(projectsFilteredFetchSuccess(data.data));
              dispatch(projectsFilteredCountFetchSuccess(projectsCount));
            }
          })
          .catch((error) => {
            dispatch(projectsFilteredFetchFail(error));
          });
      });
  };
};

//get project by ID
export const projectFetchStart = () => {
  return {
    type: actionTypes.FETCH_PROJECT_START,
  };
};
export const projectFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PROJECT_SUCCESS,
    project: data,
  };
};
export const projectFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_PROJECT_FAIL,
    error: error,
  };
};
export const projectFetch = (projectId) => {
  return (dispatch) => {
    dispatch(projectFetchStart());
    fetch(`${API}/project/${projectId}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(projectFetchFail(data.error));
        } else {
          dispatch(projectFetchSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(projectFetchFail(error));
      });
  };
};

//create project
export const projectCreateStart = () => {
  return {
    type: actionTypes.CREATE_PROJECT_START,
  };
};
export const projectCreateSuccess = () => {
  return {
    type: actionTypes.CREATE_PROJECT_SUCCESS,
  };
};
export const projectCreateFail = (error) => {
  return {
    type: actionTypes.CREATE_PROJECT_FAIL,
    error: error,
  };
};
export const projectCreate = (userId, token, projectData) => {
  return (dispatch) => {
    dispatch(projectCreateStart());
    fetch(`${API}/project/create/${userId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: projectData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(projectCreateFail(data.error));
        } else {
          dispatch(projectCreateSuccess());
        }
      })
      .catch((error) => {
        dispatch(projectCreateFail(error));
      });
  };
};

//update project
export const projectUpdateStart = () => {
  return {
    type: actionTypes.UPDATE_PROJECT_START,
  };
};
export const projectUpdateSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_PROJECT_SUCCESS,
    project: data,
  };
};
export const projectUpdateFail = (error) => {
  return {
    type: actionTypes.UPDATE_PROJECT_FAIL,
    error: error,
  };
};
export const projectUpdate = (projectId, userId, token, projectData) => {
  return (dispatch) => {
    dispatch(projectUpdateStart());
    fetch(`${API}/project/update/${projectId}/${userId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: projectData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(projectUpdateFail(data.error));
        } else {
          dispatch(projectUpdateSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(projectUpdateFail(error));
      });
  };
};

//delete project
export const projectDeleteStart = () => {
  return {
    type: actionTypes.DELETE_PROJECT_START,
  };
};
export const projectDeleteSuccess = () => {
  return {
    type: actionTypes.DELETE_PROJECT_SUCCESS,
  };
};
export const projectDeleteFail = (error) => {
  return {
    type: actionTypes.DELETE_PROJECT_FAIL,
    error: error,
  };
};
export const projectDelete = (projectId, userId, token) => {
  return (dispatch) => {
    dispatch(projectDeleteStart());
    fetch(`${API}/project/${projectId}/${userId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(projectDeleteFail(data.error));
        } else {
          dispatch(projectDeleteSuccess());
        }
      })
      .catch((error) => {
        dispatch(projectDeleteFail(error));
      });
  };
};

//clear project message
export const projectMessageClearSuccess = () => {
  return {
    type: actionTypes.CLEAR_PROJECT_MESSAGE_SUCCESS,
  };
};
export const projectMessageClear = () => {
  return (dispatch) => {
    dispatch(projectMessageClearSuccess());
  };
};
