import * as actionTypes from "./actionTypes";
import { API } from "../config";

//get all categories
export const blogCategoriesFetchStart = () => {
  return {
    type: actionTypes.FETCH_BLOG_CATEGORIES_START,
  };
};
export const blogCategoriesFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_BLOG_CATEGORIES_SUCCESS,
    categories: data.data,
  };
};
export const blogCategoriesCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_BLOG_CATEGORIES_COUNT_SUCCESS,
    count: data,
  };
};
export const blogCategoriesFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_BLOG_CATEGORIES_FAIL,
    error: error,
  };
};
export const blogCategoriesFetch = (skip, limit, myFilters) => {
  const data = {
    limit,
    skip,
    myFilters,
  };
  return (dispatch) => {
    dispatch(blogCategoriesFetchStart());
    fetch(`${API}/blogCategories/count`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ myFilters }),
    })
      .then((response) => response.json())
      .then((result) => {
        let blogCategoryCount = result.count;
        fetch(`${API}/blogCategories`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(blogCategoriesFetchFail(data.error));
            } else {
              dispatch(blogCategoriesCountFetchSuccess(blogCategoryCount));
              dispatch(blogCategoriesFetchSuccess(data));
            }
          })
          .catch((error) => {
            dispatch(blogCategoriesFetchFail(error));
          });
      });
  };
};

//get filtered blog Categories
export const blogCategoriesFilteredFetchStart = () => {
  return {
    type: actionTypes.FETCH_BLOG_CATEGORIES_FILTERED_START,
  };
};
export const blogCategoriesFilteredFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_BLOG_CATEGORIES_FILTERED_SUCCESS,
    blogCategories: data,
  };
};
export const blogCategoriesFilteredCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_BLOG_CATEGORIES_FILTERED_COUNT_SUCCESS,
    count: data,
  };
};
export const blogCategoriesFilteredFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_BLOG_CATEGORIES_FILTERED_FAIL,
    error: error,
  };
};
export const blogCategoriesFilteredFetch = (skip, limit, filters) => {
  const data = {
    limit,
    skip,
    filters,
  };
  return (dispatch) => {
    dispatch(blogCategoriesFilteredFetchStart());
    fetch(`${API}/blogCategories/filtered/count`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filters: filters }),
    })
      .then((response) => response.json())
      .then((result) => {
        let blogCategoriesCount = result.count;
        fetch(`${API}/blogCategories/filtered`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(blogCategoriesFilteredFetchFail(data.error));
            } else {
              dispatch(blogCategoriesFilteredFetchSuccess(data.data));
              dispatch(
                blogCategoriesFilteredCountFetchSuccess(blogCategoriesCount)
              );
            }
          })
          .catch((error) => {
            dispatch(blogCategoriesFilteredFetchFail(error));
          });
      });
  };
};

//get category by Name
export const blogCategoryFetchStart = () => {
  return {
    type: actionTypes.FETCH_BLOG_CATEGORY_START,
  };
};
export const blogCategoryFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_BLOG_CATEGORY_SUCCESS,
    category: data,
  };
};
export const blogCategoryFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_BLOG_CATEGORY_FAIL,
    error: error,
  };
};
export const blogCategoryFetch = (categorySlug) => {
  return (dispatch) => {
    dispatch(blogCategoryFetchStart());
    fetch(`${API}/blogCategory/${categorySlug}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(blogCategoryFetchFail(data.error));
        } else {
          dispatch(blogCategoryFetchSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(blogCategoryFetchFail(error));
      });
  };
};

//create category
export const blogCategoryCreateStart = () => {
  return {
    type: actionTypes.CREATE_BLOG_CATEGORY_START,
  };
};
export const blogCategoryCreateSuccess = () => {
  return {
    type: actionTypes.CREATE_BLOG_CATEGORY_SUCCESS,
  };
};
export const blogCategoryCreateFail = (error) => {
  return {
    type: actionTypes.CREATE_BLOG_CATEGORY_FAIL,
    error: error,
  };
};
export const blogCategoryCreate = (userId, token, blogCategoryData) => {
  return (dispatch) => {
    dispatch(blogCategoryCreateStart());
    fetch(`${API}/blogCategory/create/${userId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: blogCategoryData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(blogCategoryCreateFail(data.error));
        } else {
          dispatch(blogCategoryCreateSuccess());
        }
      })
      .catch((error) => {
        dispatch(blogCategoryCreateFail(error));
      });
  };
};

//update category
export const blogCategoryUpdateStart = () => {
  return {
    type: actionTypes.UPDATE_BLOG_CATEGORY_START,
  };
};
export const blogCategoryUpdateSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_BLOG_CATEGORY_SUCCESS,
    category: data,
  };
};
export const blogCategoryUpdateFail = (error) => {
  return {
    type: actionTypes.UPDATE_BLOG_CATEGORY_FAIL,
    error: error,
  };
};
export const blogCategoryUpdate = (
  blogCategoryId,
  userId,
  token,
  blogCategoryData
) => {
  return (dispatch) => {
    dispatch(blogCategoryUpdateStart());
    fetch(`${API}/blogCategory/update/${blogCategoryId}/${userId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: blogCategoryData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(blogCategoryUpdateFail(data.error));
        } else {
          dispatch(blogCategoryUpdateSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(blogCategoryUpdateFail(error));
      });
  };
};

//delete category
export const blogCategoryDeleteStart = () => {
  return {
    type: actionTypes.DELETE_BLOG_CATEGORY_START,
  };
};
export const blogCategoryDeleteSuccess = () => {
  return {
    type: actionTypes.DELETE_BLOG_CATEGORY_SUCCESS,
  };
};
export const blogCategoryDeleteFail = (error) => {
  return {
    type: actionTypes.DELETE_BLOG_CATEGORY_FAIL,
    error: error,
  };
};
export const blogCategoryDelete = (blogCategoryId, userId, token) => {
  return (dispatch) => {
    dispatch(blogCategoryDeleteStart());
    fetch(`${API}/blogCategory/${blogCategoryId}/${userId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(blogCategoryDeleteFail(data.error));
        } else {
          dispatch(blogCategoryDeleteSuccess());
        }
      })
      .catch((error) => {
        dispatch(blogCategoryDeleteFail(error));
      });
  };
};

//clear category message
export const blogCategoryMessageClearSuccess = () => {
  return {
    type: actionTypes.CLEAR_BLOG_CATEGORY_MESSAGE_SUCCESS,
  };
};
export const blogCategoryMessageClear = () => {
  return (dispatch) => {
    dispatch(blogCategoryMessageClearSuccess());
  };
};
