import React, { useState, useEffect } from "react";
import { Router } from "react-router-dom";
import { history } from "./history";
import { connect } from "react-redux";
import * as actions from "./store/actions/exports";
import Layout from "./containers/Layout";
import RoutesSwitch from "./RoutesSwitch";

const App = ({ isAuthenticated, userLogged }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    isAuthenticated();
  }, [isAuthenticated]);

  useEffect(() => {
    if (userLogged) {
      setIsAuth(true);
      userLogged.user.role === 1 && setIsAdmin(true);
    } else {
      setIsAuth(false);
    }
  }, [userLogged]);

  return (
    <Router history={history}>
      <Layout isAuth={isAuth}>
        <RoutesSwitch isAuth={isAuth} isAdmin={isAdmin} />
      </Layout>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    userLoggedLoading: state.user.userLoggedLoading,
    userLoggedError: state.user.userLoggedError,
    userLogged: state.user.userLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    isAuthenticated: () => dispatch(actions.isAuthenticated()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
