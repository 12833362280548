import * as actionTypes from "./actionTypes";
import { API } from "../config";

//get all categories
export const materialCategoriesFetchStart = () => {
  return {
    type: actionTypes.FETCH_MATERIAL_CATEGORIES_START,
  };
};
export const materialCategoriesFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_MATERIAL_CATEGORIES_SUCCESS,
    categories: data.data,
  };
};
export const materialCategoriesCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_MATERIAL_CATEGORIES_COUNT_SUCCESS,
    count: data,
  };
};
export const materialCategoriesFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_MATERIAL_CATEGORIES_FAIL,
    error: error,
  };
};
export const materialCategoriesFetch = (skip, limit, myFilters) => {
  const data = {
    limit,
    skip,
    myFilters,
  };
  return (dispatch) => {
    dispatch(materialCategoriesFetchStart());
    fetch(`${API}/materialCategories/count`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ myFilters }),
    })
      .then((response) => response.json())
      .then((result) => {
        let materialCategoryCount = result.count;
        fetch(`${API}/materialCategories`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(materialCategoriesFetchFail(data.error));
            } else {
              dispatch(
                materialCategoriesCountFetchSuccess(materialCategoryCount)
              );
              dispatch(materialCategoriesFetchSuccess(data));
            }
          })
          .catch((error) => {
            dispatch(materialCategoriesFetchFail(error));
          });
      });
  };
};

//get filtered material categories
export const materialCategoriesFilteredFetchStart = () => {
  return {
    type: actionTypes.FETCH_MATERIAL_CATEGORIES_FILTERED_START,
  };
};
export const materialCategoriesFilteredFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_MATERIAL_CATEGORIES_FILTERED_SUCCESS,
    materialCategories: data,
  };
};
export const materialCategoriesFilteredCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_MATERIAL_CATEGORIES_FILTERED_COUNT_SUCCESS,
    count: data,
  };
};
export const materialCategoriesFilteredFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_MATERIAL_CATEGORIES_FILTERED_FAIL,
    error: error,
  };
};
export const materialCategoriesFilteredFetch = (skip, limit, filters) => {
  const data = {
    limit,
    skip,
    filters,
  };
  return (dispatch) => {
    dispatch(materialCategoriesFilteredFetchStart());
    fetch(`${API}/materialCategories/filtered/count`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filters: filters }),
    })
      .then((response) => response.json())
      .then((result) => {
        let materialCategoriesCount = result.count;
        fetch(`${API}/materialCategories/filtered`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(materialCategoriesFilteredFetchFail(data.error));
            } else {
              dispatch(materialCategoriesFilteredFetchSuccess(data.data));
              dispatch(
                materialCategoriesFilteredCountFetchSuccess(
                  materialCategoriesCount
                )
              );
            }
          })
          .catch((error) => {
            dispatch(materialCategoriesFilteredFetchFail(error));
          });
      });
  };
};

//get category by Name
export const materialCategoryFetchStart = () => {
  return {
    type: actionTypes.FETCH_MATERIAL_CATEGORY_START,
  };
};
export const materialCategoryFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_MATERIAL_CATEGORY_SUCCESS,
    category: data,
  };
};
export const materialCategoryFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_MATERIAL_CATEGORY_FAIL,
    error: error,
  };
};
export const materialCategoryFetch = (categorySlug) => {
  return (dispatch) => {
    dispatch(materialCategoryFetchStart());
    fetch(`${API}/materialCategory/${categorySlug}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(materialCategoryFetchFail(data.error));
        } else {
          dispatch(materialCategoryFetchSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(materialCategoryFetchFail(error));
      });
  };
};

//create category
export const materialCategoryCreateStart = () => {
  return {
    type: actionTypes.CREATE_MATERIAL_CATEGORY_START,
  };
};
export const materialCategoryCreateSuccess = () => {
  return {
    type: actionTypes.CREATE_MATERIAL_CATEGORY_SUCCESS,
  };
};
export const materialCategoryCreateFail = (error) => {
  return {
    type: actionTypes.CREATE_MATERIAL_CATEGORY_FAIL,
    error: error,
  };
};
export const materialCategoryCreate = (userId, token, materialCategoryData) => {
  return (dispatch) => {
    dispatch(materialCategoryCreateStart());
    fetch(`${API}/materialCategory/create/${userId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: materialCategoryData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(materialCategoryCreateFail(data.error));
        } else {
          dispatch(materialCategoryCreateSuccess());
        }
      })
      .catch((error) => {
        dispatch(materialCategoryCreateFail(error));
      });
  };
};

//update category
export const materialCategoryUpdateStart = () => {
  return {
    type: actionTypes.UPDATE_MATERIAL_CATEGORY_START,
  };
};
export const materialCategoryUpdateSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_MATERIAL_CATEGORY_SUCCESS,
    category: data,
  };
};
export const materialCategoryUpdateFail = (error) => {
  return {
    type: actionTypes.UPDATE_MATERIAL_CATEGORY_FAIL,
    error: error,
  };
};
export const materialCategoryUpdate = (
  materialCategoryId,
  userId,
  token,
  materialCategoryData
) => {
  return (dispatch) => {
    dispatch(materialCategoryUpdateStart());
    fetch(`${API}/materialCategory/update/${materialCategoryId}/${userId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: materialCategoryData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(materialCategoryUpdateFail(data.error));
        } else {
          dispatch(materialCategoryUpdateSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(materialCategoryUpdateFail(error));
      });
  };
};

//delete category
export const materialCategoryDeleteStart = () => {
  return {
    type: actionTypes.DELETE_MATERIAL_CATEGORY_START,
  };
};
export const materialCategoryDeleteSuccess = () => {
  return {
    type: actionTypes.DELETE_MATERIAL_CATEGORY_SUCCESS,
  };
};
export const materialCategoryDeleteFail = (error) => {
  return {
    type: actionTypes.DELETE_MATERIAL_CATEGORY_FAIL,
    error: error,
  };
};
export const materialCategoryDelete = (materialCategoryId, userId, token) => {
  return (dispatch) => {
    dispatch(materialCategoryDeleteStart());
    fetch(`${API}/materialCategory/${materialCategoryId}/${userId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(materialCategoryDeleteFail(data.error));
        } else {
          dispatch(materialCategoryDeleteSuccess());
        }
      })
      .catch((error) => {
        dispatch(materialCategoryDeleteFail(error));
      });
  };
};

//clear category message
export const materialCategoryMessageClearSuccess = () => {
  return {
    type: actionTypes.CLEAR_MATERIAL_CATEGORY_MESSAGE_SUCCESS,
  };
};
export const materialCategoryMessageClear = () => {
  return (dispatch) => {
    dispatch(materialCategoryMessageClearSuccess());
  };
};
