import * as actionTypes from "./actionTypes";
import { API } from "../config";

//get all materials
export const materialsFetchStart = () => {
  return {
    type: actionTypes.FETCH_MATERIALS_START,
  };
};
export const materialsFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_MATERIALS_SUCCESS,
    materials: data,
  };
};
export const materialsCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FILTERED_MATERIALS_COUNT_SUCCESS,
    count: data,
  };
};
export const materialsFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_MATERIALS_FAIL,
    error: error,
  };
};
export const materialsFetch = (skip, limit, filters) => {
  const data = {
    limit,
    skip,
    filters,
  };
  return (dispatch) => {
    dispatch(materialsFetchStart());
    fetch(`${API}/materials/count`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filters: filters }),
    })
      .then((response) => response.json())
      .then((result) => {
        let materialsCount = result.count;
        fetch(`${API}/materials`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(materialsFetchFail(data.error));
            } else {
              dispatch(materialsFetchSuccess(data.materials));
              dispatch(materialsCountFetchSuccess(materialsCount));
            }
          })
          .catch((error) => {
            dispatch(materialsFetchFail(error));
          });
      });
  };
};

//get filtered materials
export const materialsFilteredFetchStart = () => {
  return {
    type: actionTypes.FETCH_FILTERED_MATERIALS_START,
  };
};
export const materialsFilteredFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FILTERED_MATERIALS_SUCCESS,
    materials: data,
  };
};
export const materialsFilteredCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FILTERED_MATERIALS_COUNT_SUCCESS,
    count: data,
  };
};
export const materialsFilteredFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_FILTERED_MATERIALS_FAIL,
    error: error,
  };
};
export const materialsFilteredFetch = (skip, limit, filters) => {
  const data = {
    limit,
    skip,
    filters,
  };
  return (dispatch) => {
    dispatch(materialsFilteredFetchStart());
    fetch(`${API}/materials/filtered/count`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filters: filters }),
    })
      .then((response) => response.json())
      .then((result) => {
        let materialsCount = result.count;
        fetch(`${API}/materials/filtered`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(materialsFilteredFetchFail(data.error));
            } else {
              dispatch(materialsFilteredFetchSuccess(data.data));
              dispatch(materialsFilteredCountFetchSuccess(materialsCount));
            }
          })
          .catch((error) => {
            dispatch(materialsFilteredFetchFail(error));
          });
      });
  };
};

//get material by ID
export const materialFetchStart = () => {
  return {
    type: actionTypes.FETCH_MATERIAL_START,
  };
};
export const materialFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_MATERIAL_SUCCESS,
    material: data,
  };
};
export const materialFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_MATERIAL_FAIL,
    error: error,
  };
};
export const materialFetch = (materialId) => {
  return (dispatch) => {
    dispatch(materialFetchStart());
    fetch(`${API}/material/${materialId}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(materialFetchFail(data.error));
        } else {
          dispatch(materialFetchSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(materialFetchFail(error));
      });
  };
};

//create material
export const materialCreateStart = () => {
  return {
    type: actionTypes.CREATE_MATERIAL_START,
  };
};
export const materialCreateSuccess = () => {
  return {
    type: actionTypes.CREATE_MATERIAL_SUCCESS,
  };
};
export const materialCreateFail = (error) => {
  return {
    type: actionTypes.CREATE_MATERIAL_FAIL,
    error: error,
  };
};
export const materialCreate = (userId, token, materialData) => {
  return (dispatch) => {
    dispatch(materialCreateStart());
    fetch(`${API}/material/create/${userId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: materialData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(materialCreateFail(data.error));
        } else {
          dispatch(materialCreateSuccess());
        }
      })
      .catch((error) => {
        dispatch(materialCreateFail(error));
      });
  };
};

//update material
export const materialUpdateStart = () => {
  return {
    type: actionTypes.UPDATE_MATERIAL_START,
  };
};
export const materialUpdateSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_MATERIAL_SUCCESS,
    material: data,
  };
};
export const materialUpdateFail = (error) => {
  return {
    type: actionTypes.UPDATE_MATERIAL_FAIL,
    error: error,
  };
};
export const materialUpdate = (materialId, userId, token, materialData) => {
  return (dispatch) => {
    dispatch(materialUpdateStart());
    fetch(`${API}/material/update/${materialId}/${userId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: materialData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(materialUpdateFail(data.error));
        } else {
          dispatch(materialUpdateSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(materialUpdateFail(error));
      });
  };
};

//delete material
export const materialDeleteStart = () => {
  return {
    type: actionTypes.DELETE_MATERIAL_START,
  };
};
export const materialDeleteSuccess = () => {
  return {
    type: actionTypes.DELETE_MATERIAL_SUCCESS,
  };
};
export const materialDeleteFail = (error) => {
  return {
    type: actionTypes.DELETE_MATERIAL_FAIL,
    error: error,
  };
};
export const materialDelete = (materialId, userId, token) => {
  return (dispatch) => {
    dispatch(materialDeleteStart());
    fetch(`${API}/material/${materialId}/${userId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(materialDeleteFail(data.error));
        } else {
          dispatch(materialDeleteSuccess());
        }
      })
      .catch((error) => {
        dispatch(materialDeleteFail(error));
      });
  };
};

//clear material message
export const materialMessageClearSuccess = () => {
  return {
    type: actionTypes.CLEAR_MATERIAL_MESSAGE_SUCCESS,
  };
};
export const materialMessageClear = () => {
  return (dispatch) => {
    dispatch(materialMessageClearSuccess());
  };
};
