import * as actionTypes from "./actionTypes";
import { API } from "../config";

//get all categories
export const projectCategoriesFetchStart = () => {
  return {
    type: actionTypes.FETCH_PROJECT_CATEGORIES_START,
  };
};
export const projectCategoriesFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PROJECT_CATEGORIES_SUCCESS,
    categories: data.data,
  };
};
export const projectCategoriesCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PROJECT_CATEGORIES_COUNT_SUCCESS,
    count: data,
  };
};
export const projectCategoriesFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_PROJECT_CATEGORIES_FAIL,
    error: error,
  };
};
export const projectCategoriesFetch = (skip, limit, myFilters) => {
  const data = {
    limit,
    skip,
    myFilters,
  };
  return (dispatch) => {
    dispatch(projectCategoriesFetchStart());
    fetch(`${API}/projectCategories/count`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ myFilters }),
    })
      .then((response) => response.json())
      .then((result) => {
        let projectCategoryCount = result.count;
        fetch(`${API}/projectCategories`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(projectCategoriesFetchFail(data.error));
            } else {
              dispatch(
                projectCategoriesCountFetchSuccess(projectCategoryCount)
              );
              dispatch(projectCategoriesFetchSuccess(data));
            }
          })
          .catch((error) => {
            dispatch(projectCategoriesFetchFail(error));
          });
      });
  };
};

//get filtered project categories
export const projectCategoriesFilteredFetchStart = () => {
  return {
    type: actionTypes.FETCH_PROJECT_CATEGORIES_FILTERED_START,
  };
};
export const projectCategoriesFilteredFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PROJECT_CATEGORIES_FILTERED_SUCCESS,
    projectCategories: data,
  };
};
export const projectCategoriesFilteredCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PROJECT_CATEGORIES_FILTERED_COUNT_SUCCESS,
    count: data,
  };
};
export const projectCategoriesFilteredFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_PROJECT_CATEGORIES_FILTERED_FAIL,
    error: error,
  };
};
export const projectCategoriesFilteredFetch = (skip, limit, filters) => {
  const data = {
    limit,
    skip,
    filters,
  };
  return (dispatch) => {
    dispatch(projectCategoriesFilteredFetchStart());
    fetch(`${API}/projectCategories/filtered/count`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filters: filters }),
    })
      .then((response) => response.json())
      .then((result) => {
        let projectCategoriesCount = result.count;
        fetch(`${API}/projectCategories/filtered`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(projectCategoriesFilteredFetchFail(data.error));
            } else {
              dispatch(projectCategoriesFilteredFetchSuccess(data.data));
              dispatch(
                projectCategoriesFilteredCountFetchSuccess(
                  projectCategoriesCount
                )
              );
            }
          })
          .catch((error) => {
            dispatch(projectCategoriesFilteredFetchFail(error));
          });
      });
  };
};

//get category by Name
export const projectCategoryFetchStart = () => {
  return {
    type: actionTypes.FETCH_PROJECT_CATEGORY_START,
  };
};
export const projectCategoryFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PROJECT_CATEGORY_SUCCESS,
    category: data,
  };
};
export const projectCategoryFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_PROJECT_CATEGORY_FAIL,
    error: error,
  };
};
export const projectCategoryFetch = (categorySlug) => {
  return (dispatch) => {
    dispatch(projectCategoryFetchStart());
    fetch(`${API}/projectCategory/${categorySlug}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(projectCategoryFetchFail(data.error));
        } else {
          dispatch(projectCategoryFetchSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(projectCategoryFetchFail(error));
      });
  };
};

//create category
export const projectCategoryCreateStart = () => {
  return {
    type: actionTypes.CREATE_PROJECT_CATEGORY_START,
  };
};
export const projectCategoryCreateSuccess = () => {
  return {
    type: actionTypes.CREATE_PROJECT_CATEGORY_SUCCESS,
  };
};
export const projectCategoryCreateFail = (error) => {
  return {
    type: actionTypes.CREATE_PROJECT_CATEGORY_FAIL,
    error: error,
  };
};
export const projectCategoryCreate = (userId, token, projectCategoryData) => {
  return (dispatch) => {
    dispatch(projectCategoryCreateStart());
    fetch(`${API}/projectCategory/create/${userId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: projectCategoryData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(projectCategoryCreateFail(data.error));
        } else {
          dispatch(projectCategoryCreateSuccess());
        }
      })
      .catch((error) => {
        dispatch(projectCategoryCreateFail(error));
      });
  };
};

//update category
export const projectCategoryUpdateStart = () => {
  return {
    type: actionTypes.UPDATE_PROJECT_CATEGORY_START,
  };
};
export const projectCategoryUpdateSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_PROJECT_CATEGORY_SUCCESS,
    category: data,
  };
};
export const projectCategoryUpdateFail = (error) => {
  return {
    type: actionTypes.UPDATE_PROJECT_CATEGORY_FAIL,
    error: error,
  };
};
export const projectCategoryUpdate = (
  projectCategoryId,
  userId,
  token,
  projectCategoryData
) => {
  return (dispatch) => {
    dispatch(projectCategoryUpdateStart());
    fetch(`${API}/projectCategory/update/${projectCategoryId}/${userId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: projectCategoryData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(projectCategoryUpdateFail(data.error));
        } else {
          dispatch(projectCategoryUpdateSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(projectCategoryUpdateFail(error));
      });
  };
};

//delete category
export const projectCategoryDeleteStart = () => {
  return {
    type: actionTypes.DELETE_PROJECT_CATEGORY_START,
  };
};
export const projectCategoryDeleteSuccess = () => {
  return {
    type: actionTypes.DELETE_PROJECT_CATEGORY_SUCCESS,
  };
};
export const projectCategoryDeleteFail = (error) => {
  return {
    type: actionTypes.DELETE_PROJECT_CATEGORY_FAIL,
    error: error,
  };
};
export const projectCategoryDelete = (projectCategoryId, userId, token) => {
  return (dispatch) => {
    dispatch(projectCategoryDeleteStart());
    fetch(`${API}/projectCategory/${projectCategoryId}/${userId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(projectCategoryDeleteFail(data.error));
        } else {
          dispatch(projectCategoryDeleteSuccess());
        }
      })
      .catch((error) => {
        dispatch(projectCategoryDeleteFail(error));
      });
  };
};

//clear category message
export const projectCategoryMessageClearSuccess = () => {
  return {
    type: actionTypes.CLEAR_PROJECT_CATEGORY_MESSAGE_SUCCESS,
  };
};
export const projectCategoryMessageClear = () => {
  return (dispatch) => {
    dispatch(projectCategoryMessageClearSuccess());
  };
};
