import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  blogPost: null,
  blogPostSuccess: null,
  blogPostError: null,
  blogPostLoading: false,

  blogPosts: null,
  blogPostsSuccess: null,
  blogPostsError: null,
  blogPostsLoading: false,

  blogPostsFiltered: null,
  blogPostsFilteredCount: null,
  blogPostsFilteredSuccess: null,
  blogPostsFilteredError: null,
  blogPostsFilteredLoading: false,

  blogPostsRelated: null,
  blogPostsRelatedSuccess: null,
  blogPostsRelatedError: null,
  blogPostsRelatedLoading: false,

  blogPostUpdateSuccess: null,
  blogPostUpdateError: null,
  blogPostUpdateLoading: false,

  blogPostDeleteSuccess: null,
  blogPostDeleteError: null,
  blogPostDeleteLoading: false,
};

//get all posts
const blogPostsFetchStart = (state) => {
  return updateObject(state, {
    blogPostsSuccess: null,
    blogPostsError: null,
    blogPostsLoading: true,
  });
};
const blogPostsFetchSuccess = (state, action) => {
  return updateObject(state, {
    blogPosts: action.blogPosts,
    blogPostsSuccess: true,
    blogPostsError: null,
    blogPostsLoading: false,
  });
};
const blogPostsFetchFail = (state, action) => {
  return updateObject(state, {
    blogPostsError: action.error,
    blogPostsSuccess: false,
    blogPostsLoading: false,
  });
};

//get filtered posts
const blogPostsFilteredFetchStart = (state) => {
  return updateObject(state, {
    blogPostsFilteredSuccess: null,
    blogPostsFilteredError: null,
    blogPostsFilteredLoading: true,
  });
};
const blogPostsFilteredFetchSuccess = (state, action) => {
  return updateObject(state, {
    blogPostsFiltered: action.blogPosts,
    blogPostsFilteredSuccess: true,
    blogPostsFilteredError: null,
    blogPostsFilteredLoading: false,
  });
};
const blogPostsFilteredCountFetchSuccess = (state, action) => {
  return updateObject(state, {
    blogPostsFilteredCount: action.count,
  });
};
const blogPostsFilteredFetchFail = (state, action) => {
  return updateObject(state, {
    blogPostsFilteredError: action.error,
    blogPostsFilteredSuccess: false,
    blogPostsFilteredLoading: false,
  });
};

//get related posts to post by ID
const blogPostsRelatedFetchStart = (state) => {
  return updateObject(state, {
    blogPostsRelatedSuccess: null,
    blogPostsRelatedError: null,
    blogPostsRelatedLoading: true,
  });
};
const blogPostsRelatedFetchSuccess = (state, action) => {
  return updateObject(state, {
    blogPostsRelated: action.blogPosts,
    blogPostsRelatedSuccess: true,
    blogPostsRelatedError: null,
    blogPostsRelatedLoading: false,
  });
};
const blogPostsRelatedFetchFail = (state, action) => {
  return updateObject(state, {
    blogPostsRelatedError: action.error,
    blogPostsRelatedSuccess: false,
    blogPostsRelatedLoading: false,
  });
};

//get post by Slug
const blogPostFetchStart = (state) => {
  return updateObject(state, {
    blogPostSuccess: null,
    blogPostError: null,
    blogPostLoading: true,
  });
};
const blogPostFetchSuccess = (state, action) => {
  return updateObject(state, {
    blogPost: action.blogPost,
    blogPostSuccess: true,
    blogPostError: null,
    blogPostLoading: false,
  });
};
const blogPostFetchFail = (state, action) => {
  return updateObject(state, {
    blogPostError: action.error,
    blogPostSuccess: false,
    blogPostLoading: false,
  });
};

//create post
const blogPostCreateStart = (state) => {
  return updateObject(state, {
    blogPostSuccess: null,
    blogPostError: null,
    blogPostLoading: true,
  });
};
const blogPostCreateSuccess = (state) => {
  return updateObject(state, {
    blogPostSuccess: true,
    blogPostError: null,
    blogPostLoading: false,
  });
};
const blogPostCreateFail = (state, action) => {
  return updateObject(state, {
    blogPostError: action.error,
    blogPostSuccess: false,
    blogPostLoading: false,
  });
};

//update post
const blogPostUpdateStart = (state) => {
  return updateObject(state, {
    blogPostUpdateSuccess: null,
    blogPostUpdateError: null,
    blogPostUpdateLoading: true,
  });
};
const blogPostUpdateSuccess = (state, action) => {
  return updateObject(state, {
    blogPost: action.blogPost,
    blogPostUpdateSuccess: true,
    blogPostUpdateError: null,
    blogPostUpdateLoading: false,
  });
};
const blogPostUpdateFail = (state, action) => {
  return updateObject(state, {
    blogPostUpdateError: action.error,
    blogPostUpdateSuccess: false,
    blogPostUpdateLoading: false,
  });
};

//delete post
const blogPostDeleteStart = (state) => {
  return updateObject(state, {
    blogPostDeleteSuccess: null,
    blogPostDeleteError: null,
    blogPostDeleteLoading: true,
  });
};
const blogPostDeleteSuccess = (state) => {
  return updateObject(state, {
    blogPostDeleteSuccess: true,
    blogPostDeleteError: null,
    blogPostDeleteLoading: false,
  });
};
const blogPostDeleteFail = (state, action) => {
  return updateObject(state, {
    blogPostDeleteError: action.error,
    blogPostDeleteSuccess: false,
    blogPostDeleteLoading: false,
  });
};

//clear post message
const blogPostMessageClearSuccess = (state) => {
  return updateObject(state, {
    blogPostSuccess: null,
    blogPostError: null,
    blogPostsSuccess: null,
    blogPostsError: null,
    blogPostsFilteredSuccess: null,
    blogPostsFilteredError: null,
    blogPostUpdateSuccess: null,
    blogPostUpdateError: null,
    blogPostDeleteSuccess: null,
    blogPostDeleteError: null,
    blogPosts: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_BLOG_POST_START:
      return blogPostFetchStart(state);
    case actionTypes.FETCH_BLOG_POST_SUCCESS:
      return blogPostFetchSuccess(state, action);
    case actionTypes.FETCH_BLOG_POST_FAIL:
      return blogPostFetchFail(state, action);

    case actionTypes.FETCH_BLOG_POSTS_START:
      return blogPostsFetchStart(state);
    case actionTypes.FETCH_BLOG_POSTS_SUCCESS:
      return blogPostsFetchSuccess(state, action);
    case actionTypes.FETCH_BLOG_POSTS_FAIL:
      return blogPostsFetchFail(state, action);

    case actionTypes.FETCH_RELATED_BLOG_POSTS_FAIL:
      return blogPostsRelatedFetchFail(state, action);
    case actionTypes.FETCH_RELATED_BLOG_POSTS_START:
      return blogPostsRelatedFetchStart(state);
    case actionTypes.FETCH_RELATED_BLOG_POSTS_SUCCESS:
      return blogPostsRelatedFetchSuccess(state, action);

    case actionTypes.FETCH_FILTERED_BLOG_POSTS_START:
      return blogPostsFilteredFetchStart(state);
    case actionTypes.FETCH_FILTERED_BLOG_POSTS_SUCCESS:
      return blogPostsFilteredFetchSuccess(state, action);
    case actionTypes.FETCH_FILTERED_BLOG_POSTS_COUNT_SUCCESS:
      return blogPostsFilteredCountFetchSuccess(state, action);
    case actionTypes.FETCH_FILTERED_BLOG_POSTS_FAIL:
      return blogPostsFilteredFetchFail(state, action);

    case actionTypes.CREATE_BLOG_POST_START:
      return blogPostCreateStart(state);
    case actionTypes.CREATE_BLOG_POST_SUCCESS:
      return blogPostCreateSuccess(state);
    case actionTypes.CREATE_BLOG_POST_FAIL:
      return blogPostCreateFail(state, action);

    case actionTypes.UPDATE_BLOG_POST_START:
      return blogPostUpdateStart(state);
    case actionTypes.UPDATE_BLOG_POST_SUCCESS:
      return blogPostUpdateSuccess(state, action);
    case actionTypes.UPDATE_BLOG_POST_FAIL:
      return blogPostUpdateFail(state, action);

    case actionTypes.DELETE_BLOG_POST_START:
      return blogPostDeleteStart(state);
    case actionTypes.DELETE_BLOG_POST_SUCCESS:
      return blogPostDeleteSuccess(state);
    case actionTypes.DELETE_BLOG_POST_FAIL:
      return blogPostDeleteFail(state, action);

    case actionTypes.CLEAR_BLOG_POST_MESSAGE_SUCCESS:
      return blogPostMessageClearSuccess(state);

    default:
      return state;
  }
};
export default reducer;
