import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo.png";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="footer">
      <div className="footer-middle mb-4">
        <Container>
          <Row>
            <Col xs={12} lg={6} className="footer-widget">
              <Link to="/">
                <img src={logo} alt="logo" className="footer-logo mb-4" />
              </Link>
              <p>
                Founded by Mr. Toni Gonev, the company becomes a leader in the
                region when it comes to stone processing for residential and
                commercial use. Huge commitment, energy and passion is what we
                constantly invest and what makes our work extremely professional
                and outstanding. Since 2005, we are building our reputation, our
                legacy.
              </p>
            </Col>
            <Col xs={12} lg={3} className="footer-widget mt-4 mt-lg-0">
              <h5>links</h5>
              <ul>
                <li>
                  <Link to="/">home</Link>
                </li>
                <li>
                  <Link to="/products">products</Link>
                </li>
                <li>
                  <Link to="/materials">materials</Link>
                </li>
                <li>
                  <Link to="/contact">contact</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">privacy policy</Link>
                </li>
              </ul>
            </Col>
            <Col xs={12} lg={3} className="footer-widget mt-4 mt-lg-0">
              <h5>contact info</h5>
              <ul>
                <li>
                  <a href="tel:0038975431660">mob: +389 75 431 660</a>
                </li>
                <li>
                  <a href="tel:0038934343323">tel: +389 34 343 323</a>
                </li>
                <li>
                  <a href="mailto:flex.company09@yahoo.com">
                    mail: flex.company09@yahoo.com
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-bottom">
        <Container>
          <Row className="mb-3">
            <Col xs={12} lg={6} className="text-center text-lg-left">
              {`Flex Company © ${new Date().getFullYear()} `}
              <span>
                Developed by
                <a href="https://www.produkto.io/" className="text-danger">
                  {` Produkto.io`}
                </a>
              </span>
            </Col>
            <Col xs={12} lg={6} className="text-center text-lg-right">
              <Link
                to="#"
                className="back-to-top"
                onClick={() => scrollToTop()}
              >
                back to top
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
