import * as actionTypes from "./actionTypes";
import { API } from "../config";

//get all posts
export const blogPostsFetchStart = () => {
  return {
    type: actionTypes.FETCH_BLOG_POSTS_START,
  };
};
export const blogPostsFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_BLOG_POSTS_SUCCESS,
    blogPosts: data,
  };
};
export const blogPostsCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FILTERED_BLOG_POSTS_COUNT_SUCCESS,
    count: data,
  };
};
export const blogPostsFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_BLOG_POSTS_FAIL,
    error: error,
  };
};
export const blogPostsFetch = (skip, limit, filters) => {
  const data = {
    limit,
    skip,
    filters,
  };
  return (dispatch) => {
    dispatch(blogPostsFetchStart());
    fetch(`${API}/blogPosts/count`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filters: filters }),
    })
      .then((response) => response.json())
      .then((result) => {
        let blogPostsCount = result.count;
        fetch(`${API}/blogPosts`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(blogPostsFetchFail(data.error));
            } else {
              dispatch(blogPostsFetchSuccess(data.posts));
              dispatch(blogPostsCountFetchSuccess(blogPostsCount));
            }
          })
          .catch((error) => {
            dispatch(blogPostsFetchFail(error));
          });
      });
  };
};

//get filtered posts
export const blogPostsFilteredFetchStart = () => {
  return {
    type: actionTypes.FETCH_FILTERED_BLOG_POSTS_START,
  };
};
export const blogPostsFilteredFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FILTERED_BLOG_POSTS_SUCCESS,
    blogPosts: data,
  };
};
export const blogPostsFilteredCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FILTERED_BLOG_POSTS_COUNT_SUCCESS,
    count: data,
  };
};
export const blogPostsFilteredFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_FILTERED_BLOG_POSTS_FAIL,
    error: error,
  };
};
export const blogPostsFilteredFetch = (skip, limit, filters) => {
  const data = {
    limit,
    skip,
    filters,
  };
  return (dispatch) => {
    dispatch(blogPostsFilteredFetchStart());
    fetch(`${API}/blogPosts/filtered/count`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filters: filters }),
    })
      .then((response) => response.json())
      .then((result) => {
        let blogPostsCount = result.count;
        fetch(`${API}/blogPosts/filtered`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(blogPostsFilteredFetchFail(data.error));
            } else {
              dispatch(blogPostsFilteredFetchSuccess(data.data));
              dispatch(blogPostsFilteredCountFetchSuccess(blogPostsCount));
            }
          })
          .catch((error) => {
            dispatch(blogPostsFilteredFetchFail(error));
          });
      });
  };
};

//get related posts to post by ID
export const blogPostsRelatedFetchStart = () => {
  return {
    type: actionTypes.FETCH_RELATED_BLOG_POSTS_START,
  };
};
export const blogPostsRelatedFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_RELATED_BLOG_POSTS_SUCCESS,
    blogPosts: data,
  };
};
export const blogPostsRelatedFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_RELATED_BLOG_POSTS_FAIL,
    error: error,
  };
};
export const blogPostsRelatedFetch = (blogPostId) => {
  return (dispatch) => {
    dispatch(blogPostsRelatedFetchStart());
    fetch(`${API}/blogPosts/related/${blogPostId}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => dispatch(blogPostsRelatedFetchSuccess(data)))
      .catch((error) => {
        dispatch(blogPostsRelatedFetchFail(error));
      });
  };
};

//get post by Slug
export const blogPostFetchStart = () => {
  return {
    type: actionTypes.FETCH_BLOG_POST_START,
  };
};
export const blogPostFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_BLOG_POST_SUCCESS,
    blogPost: data,
  };
};
export const blogPostFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_BLOG_POST_FAIL,
    error: error,
  };
};
export const blogPostFetch = (blogPostSlug) => {
  return (dispatch) => {
    dispatch(blogPostFetchStart());
    fetch(`${API}/blogPost/${blogPostSlug}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(blogPostFetchFail(data.error));
        } else {
          dispatch(blogPostFetchSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(blogPostFetchFail(error));
      });
  };
};

//create post
export const blogPostCreateStart = () => {
  return {
    type: actionTypes.CREATE_BLOG_POST_START,
  };
};
export const blogPostCreateSuccess = () => {
  return {
    type: actionTypes.CREATE_BLOG_POST_SUCCESS,
  };
};
export const blogPostCreateFail = (error) => {
  return {
    type: actionTypes.CREATE_BLOG_POST_FAIL,
    error: error,
  };
};
export const blogPostCreate = (userId, token, blogPostData) => {
  return (dispatch) => {
    dispatch(blogPostCreateStart());
    fetch(`${API}/blogPost/create/${userId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: blogPostData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(blogPostCreateFail(data.error));
        } else {
          dispatch(blogPostCreateSuccess());
        }
      })
      .catch((error) => {
        dispatch(blogPostCreateFail(error));
      });
  };
};

//update post
export const blogPostUpdateStart = () => {
  return {
    type: actionTypes.UPDATE_BLOG_POST_START,
  };
};
export const blogPostUpdateSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_BLOG_POST_SUCCESS,
    blogPost: data,
  };
};
export const blogPostUpdateFail = (error) => {
  return {
    type: actionTypes.UPDATE_BLOG_POST_FAIL,
    error: error,
  };
};
export const blogPostUpdate = (blogPostId, userId, token, blogPostData) => {
  return (dispatch) => {
    dispatch(blogPostUpdateStart());
    fetch(`${API}/blogPost/update/${blogPostId}/${userId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: blogPostData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(blogPostUpdateFail(data.error));
        } else {
          dispatch(blogPostUpdateSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(blogPostUpdateFail(error));
      });
  };
};

//delete post
export const blogPostDeleteStart = () => {
  return {
    type: actionTypes.DELETE_BLOG_POST_START,
  };
};
export const blogPostDeleteSuccess = () => {
  return {
    type: actionTypes.DELETE_BLOG_POST_SUCCESS,
  };
};
export const blogPostDeleteFail = (error) => {
  return {
    type: actionTypes.DELETE_BLOG_POST_FAIL,
    error: error,
  };
};
export const blogPostDelete = (blogPostId, userId, token) => {
  return (dispatch) => {
    dispatch(blogPostDeleteStart());
    fetch(`${API}/blogPost/${blogPostId}/${userId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(blogPostDeleteFail(data.error));
        } else {
          dispatch(blogPostDeleteSuccess());
        }
      })
      .catch((error) => {
        dispatch(blogPostDeleteFail(error));
      });
  };
};

//clear post message
export const blogPostMessageClearSuccess = () => {
  return {
    type: actionTypes.CLEAR_BLOG_POST_MESSAGE_SUCCESS,
  };
};
export const blogPostMessageClear = () => {
  return (dispatch) => {
    dispatch(blogPostMessageClearSuccess());
  };
};
