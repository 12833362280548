import { lazy } from "react";

const HomePage = lazy(() => import("./components/pages/HomePage"));
const DashboardPage = lazy(() => import("./components/pages/DashboardPage"));
const AccountSettingsPage = lazy(() =>
  import("./components/pages/AccountSettingsPage")
);
const ContactPage = lazy(() => import("./components/pages/ContactPage"));
const PrivacyPage = lazy(() => import("./components/pages/PrivacyPage"));
const BlogPage = lazy(() => import("./components/pages/BlogPage"));
const BlogPostPage = lazy(() => import("./components/pages/BlogPostPage"));
const ProjectsPage = lazy(() => import("./components/pages/ProjectsPage"));
const ProjectDetailsPage = lazy(() =>
  import("./components/pages/ProjectDetailsPage")
);
const MaterialsPage = lazy(() => import("./components/pages/MaterialsPage"));

const routes = [
  {
    path: "/",
    component: HomePage,
  },
  {
    path: "/dashboard",
    component: DashboardPage,
    isAdminRoute: true,
  },
  {
    path: "/dashboard/:section",
    component: DashboardPage,
    isAdminRoute: true,
    isProtected: true,
  },
  {
    path: "/dashboard/:section/:sectionId",
    component: DashboardPage,
    isAdminRoute: true,
    isProtected: true,
  },
  {
    path: "/account-settings",
    component: AccountSettingsPage,
    isProtected: true,
  },
  {
    path: "/account-settings/:section",
    component: AccountSettingsPage,
    isProtected: true,
  },
  {
    path: "/projects",
    component: ProjectsPage,
  },
  {
    path: "/projects/:projectSlug",
    component: ProjectDetailsPage,
  },
  {
    path: "/materials",
    component: MaterialsPage,
  },
  {
    path: "/blog",
    component: BlogPage,
  },
  {
    path: "/blog/:blogPostSlug",
    component: BlogPostPage,
  },
  {
    path: "/blog/category/:categorySlug",
    component: BlogPage,
  },
  {
    path: "/contact",
    component: ContactPage,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPage,
  },
  {
    path: "/login",
    component: HomePage,
  },
  {
    path: "/authentication/activate/:token",
    component: HomePage,
  },
  {
    path: "/authentication/reset/:token",
    component: HomePage,
  },
];

export default routes;
